const WithdrawStepper = ({ currentStep }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <div style={currentStep >= 1 ? styles.activeStep : styles.step}>1</div>
        Details
        <div style={styles.line}></div>
        <div style={currentStep >= 2 ? styles.activeStep : styles.step}>2</div>
        Frais
        <div style={styles.line}></div>
        <div style={currentStep >= 3 ? styles.activeStep : styles.step}>3</div>
        Confirmation
        <div style={styles.line}></div>
        <div style={currentStep >= 4 ? styles.activeStep : styles.step}>4</div>
      </div>
    );
  };

  const styles = {
    step: {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#f0f0f0',
      color: '#aaa'
    },
    activeStep: {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#007bff',
      color: '#fff'
    },
    line: {
      flex: 1,
      height: '2px',
      background: '#aaa'
    }
  };

  export default WithdrawStepper;