import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Container,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useKeycloak } from "@react-keycloak/web";

import TransactionDetailsModal from "./TransactionDetailsModal";
import { cryptoPricesState, userInfoState, userTransactionsState } from "../../states/AppState";
import { sleepwellApi } from "../../utils/SleepwellApi";

import "react-datepicker/dist/react-datepicker.css";
import TransactionUtils from "./TransactionUtils";



const UserListTransactions = () => {
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };


  const defaultStartDate = new Date("2023-01-01");
  const defaultEndDate = new Date("2024-12-31");

  const transactions = useRecoilValue(userTransactionsState);
  const user = useRecoilValue(userInfoState);
  const setUserTransactions = useSetRecoilState(userTransactionsState);
  const { initialized } = useKeycloak();

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [filteredData, setFilteredData] = useState(transactions);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const getUserTransactionsFromUserid = useCallback(() => {
    if (user.id) {
      console.log('UserTransactions', 'getUserTransactionsFromUserid()');
      sleepwellApi.getUserTransactions(user.id).then(response => {
        console.log('UserTransactions', 'getUserTransactionsFromUserid()', response);
        if (response.data !== false) {
          setUserTransactions(response.data);
        }
      }).catch((error) => { });
    }
  }, [user, setUserTransactions]);

  useEffect(() => {
    if (initialized) {
      getUserTransactionsFromUserid();
    }
  }, [initialized, getUserTransactionsFromUserid]);

  useEffect(() => {
    if (isFilterActive) {
      const newFilteredData = transactions.filter((transaction) => {
        const createdOnDate = new Date(transaction.createdOn);
        return createdOnDate >= startDate && createdOnDate <= endDate;
      });
      setFilteredData(newFilteredData);
    } else {
      setFilteredData(transactions);
    }
  }, [transactions, startDate, endDate, isFilterActive]);

  const handleFilterSubmit = () => {
    setIsFilterActive(true);
  };

  const handleClearFilter = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setIsFilterActive(false);
  };

  const handleOpenModal = (transaction) => {
    setSelectedTransaction(transaction);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTransaction(null);
  };

  // prices
  const [cryptoPrices, setCryptoPrices] = useRecoilState(cryptoPricesState);

  useEffect(() => {
    async function fetchCryptoPrices() {
      sleepwellApi.getCyrptoPrices()
        .then((response) => setCryptoPrices(response))
        .catch((e) => console.error("Error fetching crypto prices:", e));
    }
    if (!cryptoPrices) {
      fetchCryptoPrices();
    }
  }, [cryptoPrices, setCryptoPrices]);


  if (!initialized) {
    return (
      <Container>
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
      </Container>
    );
  }

  return (
    <div>
      {/* <AccountSituation /> */}
      <Segment className="transactions">
        <Header as="h3">Historique des opérations</Header>
        <Divider />
        <Container
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <Form>
            <Form.Group inline>
              <Form.Field style={{ display: "flex", alignItems: "center" }}>
                <label>Période</label>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selectionner la date de début"
                />
              </Form.Field>
              <Form.Field style={{ display: "flex", alignItems: "center" }}>
                <label>-</label>
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selectionner la date de fin"
                />
              </Form.Field>
              <Button primary onClick={handleFilterSubmit}>
                Rechercher
              </Button>
              {isFilterActive && (
                <Icon
                  name="times circle"
                  className="red"
                  link
                  size="big"
                  onClick={handleClearFilter}
                />
              )}
            </Form.Group>
          </Form>
        </Container>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Actif</Table.HeaderCell>
              <Table.HeaderCell>Quantité</Table.HeaderCell>
              <Table.HeaderCell>Statut</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {transactions.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan={6}>Il n'y a aucune transaction.</Table.Cell>
              </Table.Row>
            )}
            {filteredData.map((transaction) => (
              <Table.Row key={transaction.id}>
                <Table.Cell>
                  {new Date(transaction.createdOn).toLocaleString("fr-FR", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </Table.Cell>
                <Table.Cell>{TransactionUtils.TYPES[transaction.type]}</Table.Cell>
                <Table.Cell>{transaction.assetId}</Table.Cell>
                <Table.Cell>{transaction.amount}</Table.Cell>
                <Table.Cell className={TransactionUtils.getStatusClass(transaction.status)}>
                  {TransactionUtils.STATUSES[transaction.status]}
                </Table.Cell>
                <Table.Cell>
                  <Icon
                    name="angle right"
                    link
                    onClick={() => handleOpenModal(transaction)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          {selectedTransaction && <TransactionDetailsModal
            transaction={selectedTransaction}
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            price={cryptoPrices[selectedTransaction.assetId === "polygon" ? "matic_network" : selectedTransaction.assetId]}
          />}
        </Table>
      </Segment>
    </div>
  );
};
export default UserListTransactions;
