import React from 'react';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import App from './App';


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch(error => {
      console.error('Service Worker registration failed:', error);
    });
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<RecoilRoot><App /></RecoilRoot>);

reportWebVitals();
