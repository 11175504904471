import { atom } from 'recoil';

// The personify theme : 
import '../theming/themes/theme_arkea.css';

// Needed if you use a personify theme :
import '../theming/sleepwell-css.css';

// States theming ---------
const logoState = atom({
  key: 'logoState',
  default: 'procapital-icon.png'
  //default: 'logo-banque-postale.jpeg'
  // default: 'ca-logo.png'
  //default: 'logo-bnp.jpg'
  //default: 'logo-smc.png'
  //default: 'logo-societe-generale.png'
  //default: 'logo-bank.png'
});

// ---------------------

const userLastSyncroState = atom({
  key: 'userLastSyncroState',
  default: null
});

const userAccountsState = atom({
  key: 'userAccountsState',
  default: []
});

const userPositionsState = atom({
  key: 'userPositionsState',
  default: []
});

const userTransactionsState = atom({
  key: 'userTransactionsState',
  default: []
});

const userInfoState = atom({
  key: 'userInfoState',
  default: {}
});

const cryptoPricesState = atom({
  key: 'cryptoPricesState',
  default: {}
});

// roles
const userIsConformiteState = atom({
  key: 'userIsConformiteState',
  default: false
});
const userIsOperateurState = atom({
  key: 'userIsOperateurState',
  default: false
});

//admins
const adminAllUsersState = atom({
  key: 'adminAllUsersState',
  default: []
});

const adminAllTransactionsState = atom({
  key: 'adminAllTransactionsState',
  default: []
});

const adminPendingTransactionsState = atom({
  key: 'adminPendingTransactionsState',
  default: []
});

const adminAllPositionsState = atom({
  key: 'adminAllPositionsState',
  default: []
});

const adminAllAccountsState = atom({
  key: 'adminAllAccountsState',
  default: []
});

const adminPendingAccountsState = atom({
  key: 'adminPendingAccountsState',
  default: []
});

export {
  logoState,
  userLastSyncroState,
  userAccountsState,
  userPositionsState,
  userTransactionsState,
  userInfoState,
  cryptoPricesState,
  userIsConformiteState,
  userIsOperateurState,

  adminAllUsersState,
  adminAllTransactionsState,
  adminPendingTransactionsState,
  adminAllPositionsState,
  adminAllAccountsState,
  adminPendingAccountsState,
}
