import 'semantic-ui-css/semantic.min.css';
import React, { useCallback, useEffect } from 'react';
import { Container, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router-dom';

import AppMenu from '../components/common/navbar/AppMenu';
import TopNavBarLg from '../components/common/navbar/TopNavBarLg';
import UserListPositions from '../components/userpositions/UserListPositions';
import UserListTransactions from '../components/usertransactions/UserListTransactions';
import AccountSituation from '../components/common/AccountSituation';
import { sleepwellApi } from '../utils/SleepwellApi';
import {
    userPositionsState,
    cryptoPricesState,
    userInfoState
} from '../states/AppState';
import ActiveStaking from '../components/staking/ActiveStaking';

const Situation = () => {
    const location = useLocation();
    const user = useRecoilValue(userInfoState);
    const setUserPositions = useSetRecoilState(userPositionsState);
    const cryptoPrices = useRecoilValue(cryptoPricesState);
    const setCryptoPrices = useSetRecoilState(cryptoPricesState);
    const { keycloak, initialized } = useKeycloak();

    
    const getCryptoPrices = useCallback(() => {
        if (!cryptoPrices || Object.keys(cryptoPrices).length === 0) {
            sleepwellApi.getCyrptoPrices().then(data => {
                if (data) {
                    console.log("cryptoPrices in get cryptoPrices", cryptoPrices);
                    setCryptoPrices(data);
                }
            }).catch((e) => {});    
        }
    }, [cryptoPrices, setCryptoPrices]);


    const getUserPositionsFromUserid = useCallback(() => {
        if (user.id) {
            sleepwellApi.getUserPositions(user.id).then(response => {
                if (response && response.data) {
                    console.log("cryptoPrices in get positions", cryptoPrices);
                    var positions = response.data;
                    const findItemByAssetId = (assetId) => positions.find(item => item.assetId === assetId);

                    const getItem = (mainId, testnetId, defaultEstimation, defaultChange) => {
                        let item = findItemByAssetId(mainId) || findItemByAssetId(testnetId);
                        if (!item) {
                            item = {
                                assetId: testnetId,
                                quantity: 0,
                                stakedQuantity: 0,
                                rewardsQuantity: 0,
                            };
                        }
                        return {
                            ...item,
                            cours: cryptoPrices[mainId] || defaultEstimation,
                            variation: cryptoPrices[`${mainId}_24h_change`] || defaultChange
                        };
                    };
                
                    const bitcoinItem = getItem("bitcoin", "bitcoin_testnet", cryptoPrices.bitcoin_testnet, cryptoPrices.bitcoin_testnet_24h_change);
                    const ethereumItem = getItem("ethereum", "ethereum_goerli", cryptoPrices.ethereum_goerli, cryptoPrices.ethereum_goerli_24h_change);
                    const polygonItem = getItem("polygon", "matic_network", cryptoPrices.matic_network, cryptoPrices.matic_network_24h_change);
                
                    const tableData = [
                        {
                            type: "Bitcoin",
                            currency: "BTC",
                            ...bitcoinItem,
                            estimation: bitcoinItem.quantity * bitcoinItem.cours
                        },
                        {
                            type: "Ethereum",
                            currency: "ETH",
                            ...ethereumItem,
                            estimation: ethereumItem.quantity * ethereumItem.cours
                        },
                        {
                            type: "Polygon",
                            currency: "MATIC",
                            ...polygonItem,
                            estimation: polygonItem.quantity * polygonItem.cours
                        }
                    ];
                    setUserPositions(tableData);
                }
            }).catch((error) => { });
        }
    }, [user, setUserPositions, cryptoPrices]);


    useEffect(() => {
        getCryptoPrices();
        if (initialized) {
            getUserPositionsFromUserid();
        }
    }, [initialized, getCryptoPrices, getUserPositionsFromUserid]);


    getCryptoPrices();

    const renderContent = () => {
        if (keycloak.authenticated) {
            if (location.pathname === '/situation/wallet' || location.pathname === '/') {
                return <UserListPositions />;
            } else if (location.pathname === '/situation/operations') {
                return <UserListTransactions />;
            } else if (location.pathname === "/situation/staking") {
                return <ActiveStaking />;
            }
        }
        return null;
    };

    if (!initialized) {
        return (
            <Container>
                <Dimmer active>
                    <Loader content='Loading' />
                </Dimmer>
            </Container>
        );
    }

    getUserPositionsFromUserid();

    return (
        <div style={{ height: '100vh' }}>
            <TopNavBarLg />
            <Grid style={{ height: 'calc(100vh - 50px)', margin: 0 }}>
                <Grid.Column width={3}>
                    <AppMenu />
                </Grid.Column>
                <Grid.Column width={13}>
                    <div style={{ overflowY: 'visible', padding: '20px' }}>
                        <AccountSituation />
                        {renderContent()}
                    </div>
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default Situation;