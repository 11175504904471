import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useKeycloak } from '@react-keycloak/web';

import { sleepwellApi } from '../utils/SleepwellApi';
import { userLastSyncroState, userIsConformiteState, userIsOperateurState, userInfoState } from '../states/AppState';
import { requestNotificationPermission } from '../firebase';

const Synchronizer = () => {
  const setUserInfo = useSetRecoilState(userInfoState);
  const setUserIsConformite = useSetRecoilState(userIsConformiteState);
  const setUserIsOperateur = useSetRecoilState(userIsOperateurState);
  const setUserLastSyncro = useSetRecoilState(userLastSyncroState);

  const { keycloak } = useKeycloak();

  const synchronizeDatabaseWithKeycloak = useCallback(() => {
    const { authenticated = false } = keycloak;
    console.log('Synchronizer', 'synchronizeDatabaseWithKeycloak()', 'authenticated', authenticated);

    if (authenticated === false) { return false; }
    const { email, given_name: firstName, family_name: lastName } = keycloak.idTokenParsed;
    const isConformite = keycloak.hasRealmRole('conformite');
    const isOperateur = keycloak.hasRealmRole('operateur');

    if (isConformite || isOperateur) {
      if (keycloak.hasRealmRole("client")) {
        alert("Il y a une erreur avec votre compte, veuillez contacter le support.");
        keycloak.logout();
        return;
      }
      setUserIsConformite(isConformite);
      setUserIsOperateur(isOperateur);
      return;
    }
    sleepwellApi.synchronizeDatabaseWithKeycloak(email, firstName, lastName, keycloak.idToken).then(response => {
      if (response === false) {
        return null;
      }
      requestNotificationPermission(response.data.id, response.data.deviceTokens);
      setUserInfo(response.data);
      const date = new Date().toLocaleString();
      setUserLastSyncro(date);
    });
  }, [keycloak, setUserLastSyncro, setUserIsConformite, setUserIsOperateur, setUserInfo]);

  synchronizeDatabaseWithKeycloak();

  return (<></>);
}

export default Synchronizer;
