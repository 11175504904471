import { useState } from 'react'
import { Button, Container, Icon, Image, Menu, Sidebar } from 'semantic-ui-react'
import AppMenuService from './AppMenuService'
import { useRecoilValue } from 'recoil'
import { logoState, userInfoState } from '../../../states/AppState'

function Overlay() {
    return (
        <Container style={{
            backgroundColor: "rgba(0, 0, 0, 0.795)",
            position: "fixed",
            height: "110vh",
            width: "100%",
        }} />
    )
}

function HamIcon() {
    return <Icon className="big bars icon inverted" style={{ color: "var(--background-button1)" }} />
}

function CloseIcon() {
    return <Icon className="big close icon" style={{ color: "var(--background-button1)" }} />
}

function NavbarMb({ renderLinks }) {
    const [visible, setVisible] = useState(false)
    const [icon, setIcon] = useState(HamIcon)
    const logo = useRecoilValue(logoState)
    const user = useRecoilValue(userInfoState)

    const { routes, keycloak, handleOnSelectMenu, handleOnLogin, handleOnLogout } = AppMenuService();
    const [activeItem] = useState(window.location.pathname)

    const hideSidebar = () => {
        setIcon(HamIcon)
        setVisible(false)
    }
    const showSidebar = () => {
        setIcon(CloseIcon)
        setVisible(true)
    }
    const toggleSidebar = () => {
        visible ? hideSidebar() : showSidebar()
    }
    return (
        <>

            {visible && <Overlay />}
            <Menu inverted borderless fixed='top'>
                <Menu.Menu position={visible ? "right" : "left"}>
                    <Menu.Item onClick={toggleSidebar}>{icon}</Menu.Item>
                </Menu.Menu>
            </Menu>
            <Sidebar as={Menu} animation='overlay' icon='labeled' inverted
                vertical visible={visible} width='thin' className='sidebar'
            >
                <Container className="top-menu-items">
                    <Menu.Item>
                        <Image src={`/assets/images/${logo}`} width="35px" height="35px" style={{ margin: "0 auto" }} alt="" />
                    </Menu.Item>
                    {keycloak.authenticated &&
                        <>
                            <Menu.Item onClick={handleOnSelectMenu} name='home' active={activeItem === routes.wallet}>Portefeuille</Menu.Item>
                            <Menu.Item onClick={handleOnSelectMenu} name='operations' active={activeItem === routes.operations}>Historique des opérations</Menu.Item>
                            <Menu.Item onClick={handleOnSelectMenu} name='staking' active={activeItem === routes.staking}>Staking</Menu.Item>
                            <Menu.Item onClick={handleOnSelectMenu} name='infos' active={activeItem === routes.infos}>Mes données personnelles</Menu.Item>
                            <Menu.Item onClick={handleOnSelectMenu} name='addresses' active={activeItem === routes.addresses}>Mes adresses externes</Menu.Item>
                        </>}
                    {!keycloak.authenticated &&
                        <>
                            <Menu.Menu position='right'>
                                <Menu.Item>
                                    <Button disabled color='red'>S'enregistrer</Button>
                                </Menu.Item>
                                <Menu.Item>
                                    <Button onClick={handleOnLogin} color='red'> <Icon name='lock' />Se connecter</Button>
                                </Menu.Item>
                            </Menu.Menu>
                        </>}
                </Container>

                <Container className="bottom-menu-items">
                    {keycloak.authenticated &&
                        <>
                            <Menu.Item className='hello'>Bonjour, {user.firstName} {user.lastName}</Menu.Item>
                            <Menu.Item>
                                <Button onClick={handleOnLogout} color='red' inverted>Se déconnecter</Button>
                            </Menu.Item>
                        </>}
                </Container>
            </Sidebar>
        </>
    )
}

export default NavbarMb