import 'semantic-ui-css/semantic.min.css';
import React, { useEffect, useMemo, useState } from 'react';
import { Header, Container, Dimmer, Loader, Segment, Image, Grid, Divider } from 'semantic-ui-react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AppMenu from '../components/common/navbar/AppMenu';
import { useKeycloak } from '@react-keycloak/web';
import { cryptoPricesState, userInfoState } from '../states/AppState';
import { useParams } from 'react-router-dom';
import { sleepwellApi } from '../utils/SleepwellApi';
import WithdrawStepper from '../components/withdraw/WithdrawStepper';
import WithdrawSteps from '../components/withdraw/WithdrawSteps'
import TopNavBarLg from '../components/common/navbar/TopNavBarLg';
import PasswordOtpModal from '../components/common/PasswordOtpModal';


const WithdrawPage = () => {
  const { assetId } = useParams();
  const { initialized } = useKeycloak();
  const [amount, setAmount] = useState('');
  const user = useRecoilValue(userInfoState);
  const [externalAccounts, setExternalAccounts] = useState([]);
  const [position, setPosition] = useState(false);
  const [dest, setDest] = useState('');
  const [availableQuantity, setAvailableQuantity] = useState(null);
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleChange = (e, { name, value }) => {
    switch (name) {
      case "otp":
        setOtp(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        console.warn(`Unhandled form input name: ${name}`);
        break;
    }
  };


  const options = useMemo(() => {
    return externalAccounts.map(account => ({
      key: account.id,
      text: account.label,
      value: account.id,
    }));
  }, [externalAccounts]);
  let currency, logo, type, family;

  const assetMappings = {
    bitcoin: {
      currency: "BTC", type: "Bitcoin",
      logo: "/assets/images/35px-Bitcoin.svg.png", family: "Bitcoin",
    },
    bitcoin_testnet: {
      currency: "BTC", type: "Bitcoin",
      logo: "/assets/images/35px-Bitcoin.svg.png", family: "Bitcoin"
    },
    ethereum: {
      currency: "ETH", type: "Ethereum",
      logo: "/assets/images/35px-Ethereum-icon-purple.svg.png", family: "Ethereum"
    },
    ethereum_goerli: {
      currency: "ETH", type: "Ethereum",
      logo: "/assets/images/35px-Ethereum-icon-purple.svg.png", family: "Ethereum"
    },
    polygon: {
      currency: "MATIC", type: "Ethereum",
      logo: "/assets/images/35px-Polygon_Blockchain_Matic_Logo.jpg", family: "Ethereum"
    },
  };

  if (assetMappings[assetId]) {
    currency = assetMappings[assetId].currency;
    type = assetMappings[assetId].type;
    logo = assetMappings[assetId].logo;
    family = assetMappings[assetId].family;
  }

  // get availableQuantity
  useEffect(() => {
    async function fetchAvailableQuantity() {
      if (user.id) {
        sleepwellApi.getAvailableQuantity(user.id, assetId)
          .then((response) => setAvailableQuantity(response.data))
          .catch((e) => console.error("Erreur lors de la récuperation du montant disponible:", e));
      }
    }
    fetchAvailableQuantity();
  }, [user, assetId]);

  // get external accounts
  useEffect(() => {
    const filtered = user && user.accounts
      ? user.accounts.filter(account => assetId === "polygon"
        ? !account.internal && account.assetId === assetId && account.status === "VALIDATED"
        : !account.internal && account.assetId !== "polygon" && account.type === family && account.status === "VALIDATED")
      : [];
    setExternalAccounts(filtered);
  }, [user, type, family, assetId]);

  // set default dest
  useEffect(() => {
    if (options.length > 0) {
      setDest(options[0].value);
    }
  }, [options]);

  // get position
  useEffect(() => {
    const position = user && user.positions ? user.positions.find(position => position.assetId === assetId) : false;
    setPosition(position);
  }, [user, assetId]);

  const [formError, setFormError] = useState(null);

  const handleDestChange = (e, { value }) => setDest(value);

  const handleAmountChange = (e) => {
    const inputAmount = parseFloat(e.target.value);

    if (position && inputAmount > position.quantity) {
      setFormError("Vous ne pouvez pas retirer plus que ce que vous avez.");
    }
    else if (position && inputAmount < minWithdraw) {
      setFormError(`La quantité à retirer est inférieure à la quantité minimum(${minWithdraw}) autorisée pour le retrait.`);
    }
    else {
      setFormError(null);
    }

    setAmount(e.target.value);
  };

  // prices
  const cryptoPrices = useRecoilValue(cryptoPricesState);
  const setCryptoPrices = useSetRecoilState(cryptoPricesState);

  useEffect(() => {
    async function fetchCryptoPrices() {
      sleepwellApi.getCyrptoPrices()
        .then((response) => setCryptoPrices(response.data))
        .catch((e) => console.error("Error fetching crypto prices:", e));
    }
    if (!cryptoPrices) {
      fetchCryptoPrices();
    }
  }, [cryptoPrices, setCryptoPrices]);

  const handleSpeedSelect = (speed) => {
    setSelectedSpeed(speed);
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [apiResponse, setApiResponse] = useState(null);

  // speed
  const speedMetadata = {
    'SLOW': { label: 'FAIBLE', time: '1 heure +', order: 1 },
    'NORMAL': { label: 'STANDARD', time: '30 min', order: 2 },
    'FAST': { label: 'ÉLEVÉE', time: '10 à 20 min', order: 3 }
  };
  const initialSpeeds = Object.keys(speedMetadata).map(speed => ({
    speed,
    label: speedMetadata[speed].label,
    time: speedMetadata[speed].time,
  })).sort((a, b) => speedMetadata[a.speed].order - speedMetadata[b.speed].order);

  // eslint-disable-next-line
  const [allFees, setAllFees] = useState(null);
  const [selectedSpeed, setSelectedSpeed] = useState(null);
  const [transferSpeeds, setTransferSpeeds] = useState(initialSpeeds);
  const [isFetchingFees, setIsFetchingFees] = useState(false);
  const [fetchingFeesError, setFetchingFeesError] = useState(false);
  const [minWithdraw, setMinWithdraw] = useState(null);

  useEffect(() => {
    if (currentStep === 2) {
      const fetchFees = async () => {
        setIsFetchingFees(true);
        const body = { "fromUserId": user.id, "toAccountId": dest, "amount": amount };
        sleepwellApi.getAllFees(body)
          .then((response) => {
            setAllFees(response.data);
            const newTransferSpeeds = Object.entries(response.data).map(([speed, fee]) => {
              const metadata = speedMetadata[speed];
              var equivalent = fee * cryptoPrices[assetId];
              if (assetId === "polygon") {
                equivalent = fee * cryptoPrices["matic_network"];
              }
              return {
                speed,
                label: metadata.label,
                time: metadata.time,
                fees: fee,
                equivalent: equivalent,
              };
            }).sort((a, b) => speedMetadata[a.speed].order - speedMetadata[b.speed].order);
            setTransferSpeeds(newTransferSpeeds);
            setFetchingFeesError(null);
          })
          .catch((e) => {
            console.error("Error during get fees:", e);
            try {
              const messageRegex = /'response': '([^']+)'/;
              const match = e.data.match(messageRegex);

              if (match && match[1]) {
                setFetchingFeesError(match[1]);
              } else {
                setFetchingFeesError("Suite à une erreur technique, nous n'avons pas pu récupérer les frais de transaction.");
              }
            } catch (error) {
              setFetchingFeesError("Suite à une erreur technique, nous n'avons pas pu récupérer les frais de transaction.");
            }
          })
          .finally(() => {
            setIsFetchingFees(false);
          });
      };

      fetchFees();
    }
    // eslint-disable-next-line
  }, [currentStep]);

  useEffect(() => {
    const getMinWithdrawAmount = async () => {
      sleepwellApi.getMinWithdrawAmount(assetId)
        .then((res) => {
          setMinWithdraw(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    getMinWithdrawAmount();
  }, [assetId]);


  const goToStep = async (step) => {
    if (step === 1) {
      setFetchingFeesError(null);
    }
    else if (step === 2) {
      if (!dest) {
        alert("Veuillez vous assurer que vous avez choisi un destinaire!");
        return;
      }
      else if (!amount) {
        alert("Veuillez vous assurer que Quantité est rempli!");
        return;
      }
    }
    else if (step === 3) {
      if (!selectedSpeed) {
        alert("Veuillez vous assurer que Frais réseau est sélectionné!");
        return;
      }
    }
    else if (step === 4) {
      setIsModalOpen(false);
      setLoading(true);
      const body = {
        "fromUserId": user.id,
        "toAccountId": dest,
        "amount": amount,
        "speed": selectedSpeed,
        "credentials": {
          "password": password,
          "otp": otp
        }
      };

      try {
        const response = await sleepwellApi.withdraw(body);
        setApiResponse(response.data);
        setResponseError(null);
      } catch (e) {
        setResponseError(e);
        console.error("Error during withdrawal:", e);
      } finally {
        setLoading(false);
      }
    }
    setCurrentStep(step);
  }

  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(null);

  const handleModal = (isOpen) => {
    setIsModalOpen(isOpen);
  };


  if (!initialized) {
    return (
      <Container>
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
      </Container>
    );
  }

  return (
    <>
      <div style={{ height: '100vh' }}>
        <TopNavBarLg />
        <Grid style={{ height: 'calc(100vh - 50px)', margin: 0 }}>
          <Grid.Column width={3}>
            <AppMenu />
          </Grid.Column>
          <Grid.Column width={13}>
            <div style={{ overflowY: 'auto', padding: '20px' }}>
              <Segment>
                <WithdrawStepper currentStep={currentStep} />
                <Header as='h3' style={{ marginBottom: '0px' }}>Retirer des {currency} <Image src={logo} /></Header>
                <Divider />
                {currentStep === 1 && <WithdrawSteps.Step1 {...{ amount, handleAmountChange, availableQuantity, formError, dest, options, handleDestChange, goToStep }} />}
                {currentStep === 2 && <WithdrawSteps.Step2 {...{ currency, selectedSpeed, handleSpeedSelect, transferSpeeds, goToStep, isFetchingFees, fetchingFeesError }} />}
                {currentStep === 3 && <WithdrawSteps.Step3 {...{ goToStep, amount, assetId, selectedSpeed, transferSpeeds, dest, options, cryptoPrice: cryptoPrices[assetId === "polygon" ? "matic_network" : assetId], loading, handleModal }} />}
                {currentStep === 4 && <WithdrawSteps.Step4 {...{ goToStep, apiResponse, cryptoPrice: cryptoPrices[assetId], responseError }} />}
              </Segment>
              <PasswordOtpModal
                password={password}
                otp={otp}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                handleChange={handleChange}
                goToStep={goToStep}
                loading={loading}
              />
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </>
  );
};

export default WithdrawPage;
