import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import { Grid, Header, Divider, Form, Input, Button } from 'semantic-ui-react';


function SearchClientsForm({ firstname, lastname, label, clientId, status, handleChange, clear, search }) {
    return (
        <>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header>Rercherche clients</Header>
                        <Divider />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={3} verticalAlign="middle">
                        <label>Nom compte: </label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form.Field>
                            <Input
                                type="text"
                                id="lastname"
                                name="lastname"
                                value={lastname}
                                fluid
                                onChange={handleChange}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={3} verticalAlign="middle">
                        <label>Prénom compte: </label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form.Field>
                            <Input
                                type="text"
                                id="firstname"
                                name="firstname"
                                value={firstname}
                                fluid
                                onChange={handleChange}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={3} verticalAlign="middle">
                        <label>N° Compte: </label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form.Field>
                            <Input
                                type="text"
                                id="clientId"
                                name="clientId"
                                value={clientId}
                                fluid
                                onChange={handleChange}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                {/* <Grid.Row columns={2}>
                    <Grid.Column width={3} verticalAlign="middle">
                        <label>Statut: </label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form.Field>
                            <Input
                                type="text"
                                id="status"
                                name="status"
                                value={status}
                                fluid
                                onChange={handleChange}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row> */}
            </Grid>
            <Grid>
                <Grid.Row centered>
                    <Grid.Column width={6}>
                        <Button primary onClick={clear}>Effacer</Button>
                        <Button primary onClick={search}>Rechercher</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default SearchClientsForm;
