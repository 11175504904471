import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import {
    Container,
    Dimmer,
    Loader,
    Grid,
} from 'semantic-ui-react';
import { useKeycloak } from '@react-keycloak/web';
import AppMenu from '../components/common/navbar/AppMenu';
import UserDetail from '../components/users/UserDetail';
import TopNavBarLg from '../components/common/navbar/TopNavBarLg';


const UserInfo = () => {
    const { initialized } = useKeycloak();

    if (!initialized) {
        return (
            <Container>
                <Dimmer active>
                    <Loader content='Loading' />
                </Dimmer>
            </Container>
        );
    }

    return (
        <>
            <div style={{ height: '100vh' }}>
                <TopNavBarLg />
                <Grid style={{ height: 'calc(100vh - 50px)', margin: 0 }}>
                    <Grid.Column width={3}>
                        <AppMenu />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <div style={{ overflowY: 'auto', padding: '20px' }}>
                            <UserDetail />
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
        </>
    );
}

export default UserInfo;
