import React from 'react';
import { Button, Modal, Form, Input } from 'semantic-ui-react';

const PasswordOtpModal = ({ password, otp, isOpen, onClose, handleChange, goToStep, loading }) => {
    return (
        <Modal open={isOpen} onClose={onClose} size="small">
            <Modal.Header>Confirmez votre indentité</Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Field>
                        <label>Mot de passe</label>
                        <Input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                            required
                            />
                    </Form.Field>
                    <Form.Field>
                        <label>OTP</label>
                        <Input
                            type="text"
                            id="otp"
                            name="otp"
                            value={otp}
                            onChange={handleChange}
                            required
                            />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} negative>
                    Annuler
                </Button>
                <Button primary onClick={() => goToStep(4)}>Continuer</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default PasswordOtpModal;
