import 'semantic-ui-css/semantic.min.css';
import { Icon, Modal, Table } from "semantic-ui-react";
import UserUtils from "../../users/UserUtils";
import { useEffect, useState } from "react";
import { sleepwellApi } from "../../../utils/SleepwellApi";

const ClientDetailModal = ({ clientId, isOpen, onClose }) => {
    console.log('Client ID in Modal:', clientId);
    console.log('isOpen in Modal:', isOpen);

    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!isOpen) {
            setUser(null);
            setError(null);
        } else {
            const fetchUserData = async () => {
                if (!clientId) return;
                setIsLoading(true);
                sleepwellApi.getUser(clientId)
                    .then((response) => setUser(response.data))
                    .catch((e) => setError(e))
                    .finally(() => setIsLoading(false));
            };

            fetchUserData();
        }
    }, [clientId, isOpen]);

    if (isLoading) return <p>Chargement...</p>;
    if (error) return <p>Erreur: {error.message}</p>;

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Modal.Header>
                Détails du client
                <Icon name="close" link style={{ float: "right" }} onClick={onClose} />
            </Modal.Header>
            <Modal.Content>
                <Table className="reverted">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Nº de compte : </Table.Cell>
                            <Table.Cell>{user.id}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Civilité prénom nom : </Table.Cell>
                            <Table.Cell>
                                {UserUtils.getGender(user.gender)} {user.firstName} {user.lastName}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Adresse postale : </Table.Cell>
                            <Table.Cell>{user.address} {user.postalCode} {user.city}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Pays de résidence : </Table.Cell>
                            <Table.Cell>{UserUtils.getCountryName(user.country)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Nº de téléphone : </Table.Cell>
                            <Table.Cell>{user.phoneNumber}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Modal.Content>
        </Modal>
    );
}

export default ClientDetailModal;