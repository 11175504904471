import React, { useState } from "react";
import { Modal, Icon, Table, Form, TextArea, Button, Grid, Segment } from "semantic-ui-react";
import { Utils } from "../../utils/Utils";
import TransactionUtils from "./TransactionUtils";
import { useRecoilValue } from "recoil";
import { userIsConformiteState } from "../../states/AppState";
import { sleepwellApi } from "../../utils/SleepwellApi";


const TransactionDetailsModal = ({ transaction, isOpen, onClose, price, control = false }) => {
  const [copyFeedback, setCopyFeedback] = useState(null);
  const [copiedField, setCopiedField] = useState(null);
  const [comment, setComment] = useState('');
  const isConformite = useRecoilValue(userIsConformiteState);
  const [isLoading, setIsLoading] = useState(false);
  const [actionError, setActionError] = useState(null);

  if (!transaction) return null;


  const handleCopyClick = async (text, field) => {
    const success = await Utils.copyToClipboard(text);
    if (success) {
      setCopyFeedback('success');
      setCopiedField(field);
      setTimeout(() => {
        setCopyFeedback(null);
        setCopiedField(null);
      }, 2000);
    } else {
      setCopyFeedback('error');
      setTimeout(() => setCopyFeedback(null), 2000);
    }
  };

  const handleActionClick = async (action) => {
    setIsLoading(true);
    try {
      const response = await (action === 'reject'
        ? sleepwellApi.rejectTransaction(transaction.id, comment)
        : sleepwellApi.validateTransaction(transaction.id, comment));

      console.log(response);
      onClose();
    } catch (error) {
      console.error(`Erreur lors de l'${action === 'reject' ? 'rejet' : 'validation'} de la transaction:`, error);
      setActionError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>
        Détails de l'opération{control && " à controller"}
        <Icon name="close" link style={{ float: "right" }} onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <Table className="reverted">
          <Table.Body>
            <Table.Row>
              <Table.Cell>Statut</Table.Cell>
              <Table.Cell className={TransactionUtils.getStatusClass(transaction.status)}>{TransactionUtils.STATUSES[transaction.status]}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Date</Table.Cell>
              <Table.Cell>
                {new Date(transaction.createdOn).toLocaleString("fr-FR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>{TransactionUtils.TYPES[transaction.type]}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Actif</Table.Cell>
              <Table.Cell>{transaction.assetId}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Quantité</Table.Cell>
              <Table.Cell>{transaction.amount} ≈ {(transaction.amount * price).toFixed(2)} €</Table.Cell>
            </Table.Row>
            {["RECEIVE", "SEND"].includes(transaction.type) &&
            <>
            <Table.Row>
              <Table.Cell>{transaction.type === 'RECEIVE' ? "Adresse d'origine" : "Adresse de destination"}
              </Table.Cell>
              <Table.Cell>
                {transaction.type === 'RECEIVE' ? transaction.sender : transaction.recipient}&nbsp;
                <span onClick={() => handleCopyClick(transaction.type === 'RECEIVE' ? transaction.sender : transaction.recipient, 'address')}>
                  <i className="copy icon"></i>
                  {copiedField === 'address' &&
                    <span className={copyFeedback === "success" ? "positive" : "negative"}>
                      {copyFeedback === "success" ? "Texte copié!" : "Erreur!"}
                    </span>
                  }
                </span>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Transaction ID</Table.Cell>
              <Table.Cell>
                {transaction.txHash && <>
                  <a href={TransactionUtils.getTxUrl(transaction.assetId, transaction.txHash)} target="_blank" rel="noopener noreferrer">
                    {transaction.txHash}
                  </a>&nbsp;
                  <span onClick={() => handleCopyClick(transaction.txHash, 'txHash')}>
                    <i className="copy icon"></i>
                    {copiedField === 'txHash' &&
                      <span className={copyFeedback === "success" ? "positive" : "negative"}>
                        {copyFeedback === "success" ? "Texte copié!" : "Erreur!"}
                      </span>
                    }
                  </span>
                </>}
              </Table.Cell>
            </Table.Row>
            {isConformite && control
              ?
              <Table.Row>
                <Table.Cell>Note conformité</Table.Cell>
                <Table.Cell>{transaction.complianceNote}</Table.Cell>
              </Table.Row>
              :
              <Table.Row>
                <Table.Cell>Frais de réseau</Table.Cell>
                <Table.Cell>{transaction.fees} ≈ {(transaction.fees * price).toFixed(2)} €</Table.Cell>
              </Table.Row>
            }            
            </>
            }
          </Table.Body>
        </Table>
      </Modal.Content>
      {isConformite && control && <>
        <Modal.Header>
          Commentaire justificatif
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field
              control={TextArea}
              label='Commentaire justificatif'
              value={comment}
              onChange={(e, { value }) => setComment(value)}
            />
            {actionError &&
              <Segment basic textAlign='right' style={{ paddingBottom: 0, marginBottom: 0 }}>
                <span style={{ color: 'red', paddingBottom: 0, marginBottom: 0 }}>
                  {actionError.response.data.status + ": " + actionError.response.data.message}
                </span>
              </Segment>
            }
            <Grid>
              <Grid.Column floated='right' width={5}>
                <Segment basic textAlign='right'>
                  <Button loading={isLoading} disabled={isLoading} onClick={() => handleActionClick('reject')}>Rejeter</Button>
                  <Button primary loading={isLoading} disabled={isLoading} onClick={() => handleActionClick('validate')}>Valider</Button>
                </Segment>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
      </>}
    </Modal>
  );
};

export default TransactionDetailsModal;
