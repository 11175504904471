import 'semantic-ui-css/semantic.min.css';
import React, { useCallback, useEffect } from 'react';
import { Header, Table, Divider, Icon } from 'semantic-ui-react';
import { adminPendingAccountsState } from '../../../states/AppState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useKeycloak } from '@react-keycloak/web';
import { sleepwellApi } from '../../../utils/SleepwellApi';

const AccountListTable = ({ openAccountModal, openClientModal, isAccountModalOpen }) => {
    const { initialized } = useKeycloak()
    const setPendingAccounts = useSetRecoilState(adminPendingAccountsState);

    const getPendingAccounts = useCallback(() => {
        if (initialized) {
            console.log('WaitingActions', 'getPendingAccounts()');
            sleepwellApi.getPendingAccounts().then(response => {
                console.log('WaitingActions', 'getPendingAccounts()', response);
                if (response && response.data && response.data.content) {
                    setPendingAccounts(response.data.content);
                }
            }).catch(error => { });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, setPendingAccounts, isAccountModalOpen]);

    const accounts = useRecoilValue(adminPendingAccountsState);

    useEffect(() => {
        if (initialized) {
            getPendingAccounts();
        }
    }, [initialized, getPendingAccounts]);

    return (
        <>
            <Header as='h4'>ADRESSES EXTERNES A CONTROLER</Header>
            <Divider />
            <div>Nombre de résultats: {accounts.filter((a) => a.status === "PENDING_APPROVAL").length}</div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date de création</Table.HeaderCell>
                        <Table.HeaderCell>N° compte</Table.HeaderCell>
                        <Table.HeaderCell>Libellé</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Détails</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {accounts.filter((a) => a.status === "PENDING_APPROVAL").length === 0 && (
                        <Table.Row>
                            <Table.Cell colSpan={6}>Il n'y a aucune adresse externe en attente.</Table.Cell>
                        </Table.Row>
                    )}
                    {accounts.filter((a) => a.status === "PENDING_APPROVAL").map((account) => (
                        <Table.Row key={account.id}>
                            <Table.Cell>
                                {new Date(account.createdOn).toLocaleString("fr-FR", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                })}
                            </Table.Cell>
                            <Table.Cell>
                                <button
                                    onClick={() => openClientModal(account.clientId)}
                                    style={{
                                        background: 'none',
                                        color: 'blue',
                                        textDecoration: 'none',
                                        padding: 0,
                                        border: 'none',
                                        cursor: 'pointer'
                                    }}
                                >{account.clientId}</button>
                            </Table.Cell>
                            <Table.Cell>{account.label}</Table.Cell>
                            <Table.Cell>{account.assetId}</Table.Cell>
                            <Table.Cell>
                                <Icon
                                    name="angle right"
                                    circular
                                    inverted
                                    link
                                    onClick={() => openAccountModal(account)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    );
}

export default AccountListTable;
