import 'semantic-ui-css/semantic.min.css';
import React, { useCallback, useEffect } from 'react';
import { Header, Table, Divider, Icon } from 'semantic-ui-react';
import { adminPendingTransactionsState } from '../../../states/AppState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useKeycloak } from '@react-keycloak/web';
import { sleepwellApi } from '../../../utils/SleepwellApi';
import TransactionUtils from '../../usertransactions/TransactionUtils';

const TransactionListTable = ({ openTransactionModal, openClientModal, isTransactionModalOpen }) => {
    const { initialized } = useKeycloak()
    const setPendingTransactions = useSetRecoilState(adminPendingTransactionsState);

    const getPendingTransactions = useCallback(() => {
        if (initialized) {
            console.log('WaitingActions', 'getPendingTransactions()');
            sleepwellApi.getPendingTransactions().then(response => {
                console.log('WaitingActions', 'getPendingTransactions()', response);
                if (response && response.data && response.data.content) {
                    setPendingTransactions(response.data.content);
                }
            }).catch(error => { });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, setPendingTransactions, isTransactionModalOpen])

    const transactions = useRecoilValue(adminPendingTransactionsState);

    useEffect(() => {
        if (initialized) {
            getPendingTransactions();
        }
    }, [initialized, getPendingTransactions]);

    return (
        <>
            <Header as='h4'>DEPOTS A CONTROLER</Header>
            <Divider />
            <div>Nombre de résultats: {transactions.filter((t) => t.type === "RECEIVE" && t.status === "PENDING_APPROVAL").length}</div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date de création</Table.HeaderCell>
                        <Table.HeaderCell>N° compte</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Actif</Table.HeaderCell>
                        <Table.HeaderCell>Quantité</Table.HeaderCell>
                        <Table.HeaderCell>Détails</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {transactions.filter((t) => t.type === "RECEIVE" && t.status === "PENDING_APPROVAL").length === 0 && (
                        <Table.Row>
                            <Table.Cell colSpan={6}>Il n'y a aucune transaction en attente.</Table.Cell>
                        </Table.Row>
                    )}
                    {transactions.filter((t) => t.type === "RECEIVE" && t.status === "PENDING_APPROVAL").map((transaction) => (
                        <Table.Row key={transaction.id}>
                            <Table.Cell>
                                {new Date(transaction.createdOn).toLocaleString("fr-FR", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                })}
                            </Table.Cell>

                            <Table.Cell>
                                <button
                                    onClick={() => openClientModal(transaction.clientId)}
                                    style={{
                                        background: 'none',
                                        color: 'blue',
                                        textDecoration: 'none',
                                        padding: 0,
                                        border: 'none',
                                        cursor: 'pointer'
                                    }}
                                >{transaction.clientId}</button>
                            </Table.Cell>
                            <Table.Cell>{TransactionUtils.TYPES[transaction.type]}</Table.Cell>
                            <Table.Cell>{transaction.assetId}</Table.Cell>
                            <Table.Cell>{transaction.amount}</Table.Cell>
                            <Table.Cell>
                                <Icon
                                    name="angle right"
                                    circular
                                    inverted
                                    link
                                    onClick={() => openTransactionModal(transaction)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    );
}

export default TransactionListTable;
