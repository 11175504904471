import { Image } from "semantic-ui-react";

const CryptoLogos = {
    BTC: (
      <Image
        src="/assets/images/35px-Bitcoin.svg.png"
        alt="Bitcoin logo"
      />
    ),
    ETH: (
      <Image
        src="/assets/images/35px-Ethereum-icon-purple.svg.png"
        alt="Ethereum logo"
      />
    ),
    MATIC: (
      <Image
        src="/assets/images/35px-Polygon_Blockchain_Matic_Logo.jpg"
        alt="Polygon logo"
      />
    ),
  };

  export default CryptoLogos;