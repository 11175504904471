import 'semantic-ui-css/semantic.min.css';
import React, { useEffect, useState } from 'react';
import TopNavBarLg from '../common/navbar/TopNavBarLg';
import { Button, Card, Divider, Form, Grid, Header, Icon, Input, Label, Modal, Segment } from 'semantic-ui-react';
import AppMenu from '../common/navbar/AppMenu';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '../../states/AppState';
import { Link, useParams } from 'react-router-dom';
import AppMenuService from '../common/navbar/AppMenuService';
import CryptoLogos from '../common/CyptoLogos';
import { sleepwellApi } from '../../utils/SleepwellApi';
import StakingActions from './StakingActions';


const NewStakingForm = () => {
    const { assetId } = useParams();
    const { routes } = AppMenuService();
    const user = useRecoilValue(userInfoState);
    const [quantity, setQuantity] = useState(0);
    const [availableQuantity, setAvailableQuantity] = useState(0);
    const [position, setPosition] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pathname] = useState(window.location.pathname);
    const [action, setAction] = useState(undefined);
    const [maxValue, setMaxValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(undefined);



    // get availableQuantity
    useEffect(() => {
        async function fetchAvailableQuantity() {
            if (user.id) {
                sleepwellApi.getAvailableQuantity(user.id, assetId)
                    .then((response) => setAvailableQuantity(response.data))
                    .catch((e) => console.error("Erreur lors de la récuperation du montant disponible:", e));
            }
        }
        async function fetchPosition() {
            sleepwellApi.getUserPositions(user.id)
                .then((response) => {
                    setPosition(response.data.find(position => position.assetId === assetId));
                })
                .catch((e) => { });
        }
        if (user && Object.keys(user).length !== 0) {
            fetchAvailableQuantity();
            fetchPosition();
            if (pathname === routes.stake(assetId))
                setMaxValue(availableQuantity);
            else
                setMaxValue(position.stakedQuantity);
        }
        if (action === undefined) {
            if (pathname === routes.stake(assetId))
                setAction(StakingActions.STAKE);
            else
                setAction(StakingActions.UNSTAKE);

        }
    }, [user, assetId, pathname, action, routes, availableQuantity, position.stakedQuantity]);

    const handleChange = (e, { value }) => {
        if (value <= maxValue && value >= 0) {
            setQuantity(value);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        if (action === StakingActions.STAKE) {
            sleepwellApi.stake(position.id, quantity)
                .then((response) => {
                    setIsModalOpen(true);
                })
                .catch((e) => {
                    console.log("error staking", e);
                    setError(e);
                })
                .finally(() => setIsLoading(false));
        }
        else {
            sleepwellApi.unstake(position.id, quantity)
                .then((response) => {
                    setIsModalOpen(true);
                })
                .catch((e) => {
                    console.log("error staking", e);
                    setError(e);
                })
                .finally(() => setIsLoading(false));
        }
    }

    const handleMaxClick = () => {
        setQuantity(maxValue);
    }

    const Max = ({ onClick }) =>
        <Label className='max-label circular' onClick={onClick}>
            max
        </Label>;

    return (
        <>
            <Header as='h3'>Staker des ethereum</Header>
            <Divider />
            <span className="small-text grey">
                pour en apprendre plus sur le stacking, lisez notre&nbsp;
                <a href="#top"
                //  target="_blank" rel="noreferrer noopener"
                >FAQ</a>
                &nbsp;et le&nbsp;
                <a href="#section"
                // target="_blank" rel="noreferrer noopener"
                >
                    document d'information sur les risques
                </a>
                &nbsp;associés à cette activité</span>
            <Form onSubmit={handleSubmit}>
                <Card style={{ width: '50vh' }}>
                    <Card.Content>
                        <Grid divided="vertically">
                            <Grid.Row columns={2}>
                                <Grid.Column width={10}>
                                    <Form.Field>
                                        <label>Saisissez une quantité</label>
                                        <Input
                                            type='number'
                                            max={maxValue}
                                            placeholder={0}
                                            value={quantity}
                                            onChange={handleChange}
                                        />
                                    </Form.Field>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Quantité disponible : {availableQuantity.toFixed(9)}</span>
                                        {action === StakingActions.STAKE && <>&nbsp;<Max onClick={handleMaxClick} /></>}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Quantité stakée : {position && position.stakedQuantity && position.stakedQuantity.toFixed(9)}</span>
                                        {action === StakingActions.UNSTAKE && <>&nbsp;<Max onClick={handleMaxClick} /></>}
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={6} verticalAlign='middle' textAlign='center'>
                                    <span>ETH {CryptoLogos["ETH"]}</span>
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </Card.Content>
                </Card>
                <div >
                    {error &&
                        <Segment basic style={{ paddingLeft: 0, paddingBottom: 0, marginBottom: 5 }}>
                            <span style={{ color: 'red', paddingBottom: 0, marginBottom: 0 }}>
                                {error.response.data.status + ": " + error.response.data.message}
                            </span>
                        </Segment>
                    }
                    <Button
                        as={Link} to={routes.staking}
                        loading={isLoading}
                        disabled={isLoading}

                    >Retour</Button>
                    <Button
                        type='submit'
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        {action === StakingActions.STAKE ? "Staker" : "Retirer"}
                    </Button>
                </div>
            </Form>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <Modal.Header>
                    <Header as='h3'>Staker des ethereum</Header>
                </Modal.Header>
                <Modal.Content style={{ textAlign: 'center' }}>
                    <Icon className='staking-success-icon circular checkmark' size='massive' />
                    <p className='staking-success-text'>Votre demande de {action === StakingActions.STAKE ? "staking" : "retrait"} de {quantity} ETH a été soumise.</p>
                </Modal.Content>
                <Modal.Actions style={{ textAlign: 'center' }}>
                    <Button as={Link} to={routes.staking}
                        style={{ width: '20vh' }}>
                        Retour
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

const NewStaking = () => {
    return (<>
        <div style={{ height: '100vh' }}>
            <TopNavBarLg />
            <Grid style={{ height: 'calc(100vh - 50px)', margin: 0 }}>
                <Grid.Column width={3}>
                    <AppMenu />
                </Grid.Column>
                <Grid.Column width={13}>
                    <div style={{ padding: '20px' }}>
                        <Segment>
                            <NewStakingForm />
                        </Segment>
                    </div>
                </Grid.Column>
            </Grid>
        </div>
    </>);
};

export default NewStaking;
