import 'semantic-ui-css/semantic.min.css';
import React, { useEffect, useState } from "react";
import { Modal, Icon, Table, Header, Divider, Message } from "semantic-ui-react";
import { AccountStatusUtils } from "./AccountStatusUtils";
import { sleepwellApi } from "../../utils/SleepwellApi";
import { getCountryNameByAlpha3 } from "country-locale-map";
import { useRecoilValue } from "recoil";
import { userInfoState } from "../../states/AppState";


const AccountDetailsModal = ({ account, isOpen, onClose }) => {
    const [tier, setTier] = useState(null);
    const [fetchTierError, setFetchTierError] = useState(null);
    const user = useRecoilValue(userInfoState);

    useEffect(() => {
        const fetchTier = async () => {
            if (account && account.tiersId) {
                sleepwellApi.getTier(account.tiersId)
                    .then((response) => {
                        setTier(response.data);
                    })
                    .catch((e) => {
                        setFetchTierError(e);
                        console.error("Error fetching the tier:", e);
                    });
            }
        };

        fetchTier();
    }, [account]);

    const handleClose = () => {
        setTier(null);
        setFetchTierError(null);
        onClose();
    }

    if (!account) return null;

    const renderIdentificationSection = () => {
        if (fetchTierError) {
            return <Message error content={fetchTierError.message} />;
        }

        var identificationInfo = null;
        if (account.tiersId != null) {
            identificationInfo = tier;
            if (identificationInfo == null)
                return <Message error content="erreur lors du retrait des information du titulaire" />;
        }
        else {
            identificationInfo = user;
        }
        if (identificationInfo == null) {
            return <Message error content="erreur lors du retrait des information du titulaire" />;
        }

        return (
            <>
                <Header>Identification du titulaire</Header>
                <Divider />
                <Table className="reverted">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={6}>Prénom</Table.Cell>
                            <Table.Cell>
                                {identificationInfo.firstName}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Nom</Table.Cell>
                            <Table.Cell>
                                {identificationInfo.lastName}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Pays de résidence</Table.Cell>
                            <Table.Cell>{getCountryNameByAlpha3(identificationInfo.country)}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </>
        );
    };


    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Modal.Header>
                <Icon name="close" link style={{ float: "right" }} onClick={handleClose} />
            </Modal.Header>
            <Modal.Content>
                <Header>Identitification adresse externe</Header>
                <Divider />
                <Table className="reverted">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={6}>Libellé</Table.Cell>
                            <Table.Cell>
                                {account.label}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Réseau</Table.Cell>
                            <Table.Cell>
                                {account.type}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Adresse</Table.Cell>
                            <Table.Cell>{account.address}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Statut</Table.Cell>
                            <Table.Cell className={AccountStatusUtils.getStatusClass(account.status)}>{AccountStatusUtils.STATUSES[account.status]}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                {renderIdentificationSection()}
            </Modal.Content>
        </Modal>
    );
};

export default AccountDetailsModal;
