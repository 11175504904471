import 'semantic-ui-css/semantic.min.css';
import React, { useEffect, useState } from 'react';
import { Segment, Grid, Container, Dimmer, Loader } from 'semantic-ui-react';
import TopNavBarLg from '../components/common/navbar/TopNavBarLg';
import AppMenu from '../components/common/navbar/AppMenu';
import { cryptoPricesState, userIsConformiteState } from '../states/AppState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useKeycloak } from '@react-keycloak/web';
import { sleepwellApi } from '../utils/SleepwellApi';
import ClientDetailModal from '../components/admins/waitingActions/ClientDetailModal';
import AppMenuService from '../components/common/navbar/AppMenuService';
import SearchClientsForm from '../components/admins/SearchClients/SearchClientsForm';
import ClientListTable from '../components/admins/SearchClients/ClientListTable';

function SearchClients() {
    const { navigate, routes } = AppMenuService();
    const { initialized } = useKeycloak();
    const isConformite = useRecoilValue(userIsConformiteState);


    const [cryptoPrices, setCryptoPrices] = useRecoilState(cryptoPricesState);

    useEffect(() => {
        async function fetchCryptoPrices() {
            sleepwellApi.getCyrptoPrices().then(response => {
                if (response) {
                    setCryptoPrices(response);
                }
            }).catch((e) => { });
        }
        if (!cryptoPrices || Object.keys(cryptoPrices).length === 0) {
            fetchCryptoPrices();
        }
    }, [cryptoPrices, setCryptoPrices]);

    const [label, setLabel] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [clientId, setClientId] = useState("");
    const [status, setStatus] = useState("");

    const handleChange = (e, { name, value }) => {
        switch (name) {
            case "label":
                setLabel(value);
                break;
            case "clientId":
                setClientId(value);
                break;
            case "status":
                setStatus(value);
                break;
            case "firstname":
                setFirstname(value);
                break;
            case "lastname":
                setLastname(value);
                break;
            default:
                console.warn(`Unhandled form input name: ${name}`);
                break;
        }
    };

    const [filteredClients, setFilteredClients] = useState(null);

    const clear = () => {
        setLabel("");
        setClientId("");
        setStatus("");
        setFirstname("");
        setLastname("");
        setFilteredClients(null);
    }

    const search = () => {
        const params = new URLSearchParams();
        // params.append('labels', label);
        params.append('firstNames', firstname);
        params.append('lastNames', lastname);
        params.append('ids', clientId);

        sleepwellApi.getAllClients(params).then(response => {
            console.log('SearchClients', 'search()', response);
            if (response && response.data && response.data.content) {
                setFilteredClients(response.data.content);
            }
        }).catch(error => { });

    }


    const [isClientModalOpen, setClientModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    const openClientModal = async (clientId) => {
        setSelectedClient(clientId);
        setClientModalOpen(true);
    };

    const closeClientModal = () => {
        setSelectedClient(null);
        setClientModalOpen(false);
    };

    useEffect(() => {
        if (!isConformite) {
            navigate(routes.home);
        }
    }, [isConformite, navigate, routes]);


    if (!initialized) {
        return (
            <Container>
                <Dimmer active>
                    <Loader content='Loading' />
                </Dimmer>
            </Container>
        )
    }


    return (
        <div style={{ height: '100vh' }}>
            <TopNavBarLg />
            <Grid style={{ height: 'calc(100vh - 50px)', margin: 0 }}>
                <Grid.Column width={3}>
                    <AppMenu />
                </Grid.Column>
                <Grid.Column width={13}>
                    <div style={{ overflowY: 'auto', padding: '20px' }}>

                        <Segment>
                            <SearchClientsForm
                                firstname={firstname}
                                lastname={lastname}
                                label={label}
                                clientId={clientId}
                                status={status}
                                handleChange={handleChange}
                                clear={clear}
                                search={search}
                            />
                            {filteredClients &&
                                <ClientListTable
                                    filteredClients={filteredClients}
                                    openClientModal={openClientModal}
                                />
                            }

                            <React.Fragment>
                                {isClientModalOpen && selectedClient && (
                                    <ClientDetailModal
                                        clientId={selectedClient}
                                        isOpen={isClientModalOpen}
                                        onClose={closeClientModal}
                                    />
                                )}
                            </React.Fragment>
                        </Segment>
                    </div>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default SearchClients;
