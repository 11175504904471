import { getCountryNameByAlpha3 } from 'country-locale-map';

const getGender = (gender) => {
  if (gender === "Monsieur")
    return "M.";
  else if (gender === "Madame")
    return "Mme";
}

const getCountryName = (countryCode) => {
  return getCountryNameByAlpha3(countryCode) || countryCode || "";
}


const UserUtils = {
  getGender,
  getCountryName,
}

export default UserUtils;