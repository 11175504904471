import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Divider,
  Header,
  Icon,
  List,
  Segment,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import { userAccountsState } from '../../states/AppState';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { Utils } from '../../utils/Utils';
import { AccountStatusUtils } from './AccountStatusUtils';
import AccountDetailsModal from './AccountDetailsModal';
import AppMenuService from '../common/navbar/AppMenuService';


const ListAddresses = () => {
  const accounts = useRecoilValue(userAccountsState);
  const externals = accounts.filter((account) => account.internal === false);
  const internals = accounts.filter((account) => account.internal === true);
  const [copyFeedback, setCopyFeedback] = useState(null);
  const [copiedField, setCopiedField] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { navigate, routes } = AppMenuService();

  const handleOpenModal = (account) => {
    setSelectedAccount(account);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedAccount(null);
  };

  const handleCopyClick = async (text, field) => {
    const success = await Utils.copyToClipboard(text);
    if (success) {
      setCopyFeedback('success');
      setCopiedField(field);
      setTimeout(() => {
        setCopyFeedback(null);
        setCopiedField(null);
      }, 2000);
    } else {
      setCopyFeedback('error');
      setTimeout(() => setCopyFeedback(null), 2000);
    }
  };

  return (
    <div>
      <Segment className="deposit-addresses">
        <Header as='h3'>Mes adresses de dépot</Header>
        <Divider />
        <List>
          {internals.map((account) => (
            <List.Item key={account.assetId}>
              <p>
                <span className='deposit-addresses-asset'>{account.assetId}</span> : {account.address}&nbsp;
                <span onClick={() => handleCopyClick(account.address, account.assetId)}>
                  <i className="copy icon"></i>
                  {copiedField === account.assetId &&
                    <span className={copyFeedback === "success" ? "positive" : "negative"}>
                      {copyFeedback === "success" ? "Texte copié!" : "Erreur!"}
                    </span>
                  }
                </span>
              </p>
            </List.Item>
          ))}

        </List>
      </Segment>

      <Segment className="external-addresses">
        <Header as='h3'>Mes adresses externes</Header>
        <Divider />
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>
                Libellé
              </TableHeaderCell>
              <TableHeaderCell>
                Adresse
              </TableHeaderCell>
              <TableHeaderCell>
                Réseau
              </TableHeaderCell>
              <TableHeaderCell>
                Statut
              </TableHeaderCell>
              <TableHeaderCell>
                Fiche TFR
              </TableHeaderCell>
              {/* <TableHeaderCell /> */}
            </TableRow>
          </TableHeader>
          <TableBody>
            {externals.map((account) => (
              <TableRow key={account.id}>
                <Table.Cell>
                  {account.label}
                </Table.Cell>
                <Table.Cell>
                  {account.address}
                </Table.Cell>
                <Table.Cell>
                  {account.type}
                </Table.Cell>
                <Table.Cell className={AccountStatusUtils.getStatusClass(account.status)}>
                  {AccountStatusUtils.STATUSES[account.status]}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  <Icon
                    name="angle right"
                    link
                    onClick={() => handleOpenModal(account)}
                  />
                </Table.Cell>
                {/* <Table.Cell>
                  <Button onClick={() => sleepwellApi.deactivateAddress(account)} disabled={true} >
                    Désactiver
                  </Button>
                </Table.Cell> */}
              </TableRow>
            ))}
          </TableBody>
          <AccountDetailsModal
            account={selectedAccount}
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
        </Table>
        <div className='add-external-addresses'>
          <Button onClick={() => navigate(routes.addAddress)}>
            Ajouter une adresse
          </Button>
        </div>
      </Segment>
    </div>
  );
}

export default ListAddresses;