import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { useRecoilValue } from 'recoil';
import { cryptoPricesState } from '../../../states/AppState';
import { Utils } from '../../../utils/Utils';

const getGlobalAmount = (client, prices) => {
    const amount = client.positions.reduce((acc, position) => {
        return acc + (position.quantity * prices[position.assetId]);
    }, 0);
    return Utils.formatBalance(amount);
}

const ClientListTable = ({ filteredClients, openClientModal }) => {
    const cryptoPrices = useRecoilValue(cryptoPricesState);

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>N° compte</Table.HeaderCell>
                        <Table.HeaderCell>Libellé compte</Table.HeaderCell>
                        <Table.HeaderCell>Montant global</Table.HeaderCell>
                        <Table.HeaderCell>Statut</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {filteredClients.length === 0 && (
                        <Table.Row>
                            <Table.Cell colSpan={6}>Il n'y a aucun client correspondant.</Table.Cell>
                        </Table.Row>
                    )}
                    {filteredClients.map((client) => (
                        <Table.Row key={client.email}>
                            <Table.Cell>
                                <button
                                    onClick={() => openClientModal(client.id)}
                                    style={{
                                        background: 'none',
                                        color: 'blue',
                                        textDecoration: 'none',
                                        padding: 0,
                                        border: 'none',
                                        cursor: 'pointer'
                                    }}
                                >{client.id}</button>
                            </Table.Cell>
                            <Table.Cell>{client.firstName}</Table.Cell>
                            <Table.Cell>{getGlobalAmount(client, cryptoPrices)}</Table.Cell>
                            <Table.Cell>
                                {client.email}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    );
}

export default ClientListTable;
