import React, { useEffect, useState } from "react";
import { Divider, Dropdown, Header, Icon, Segment, Table } from "semantic-ui-react";
import { useRecoilValue } from "recoil";
import { userPositionsState } from "../../states/AppState";
import CryptoLogos from "../common/CyptoLogos";
import { Link } from "react-router-dom";
import AppMenuService from "../common/navbar/AppMenuService";
import { sleepwellApi } from "../../utils/SleepwellApi";


const ActiveStaking = () => {
    const { routes } = AppMenuService();
    const positions = useRecoilValue(userPositionsState);
    const [rendement, setRendement] = useState({});

    useEffect(() => {
        positions.filter(position => ["etheureum", "ethereum_goerli"].includes(position.assetId)).map((position) => {
            sleepwellApi.getGrossApy(position.assetId)
                .then((response) => {
                    setRendement(prevRendement => ({ ...prevRendement, [position.assetId]: response.data }));
                })
                .catch((e) => { console.log(e) });
            return true;
        })
    }, [positions]);



    return (
        <div>
            <Segment className="activeStaking">
                <Header as='h3'>Vos Actifs Stakés</Header>
                <Divider />
                <span className="small-text grey">
                    pour en apprendre plus sur le stacking, lisez notre&nbsp;
                    <a href="#faq"
                    //  target="_blank" rel="noreferrer noopener"
                    >FAQ</a>
                    &nbsp;et le&nbsp;
                    <a href="#infos"
                    // target="_blank" rel="noreferrer noopener"
                    >
                        document d'information sur les risques
                    </a>
                    &nbsp;associés à cette activité</span>
                <Table >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Crypto
                            </Table.HeaderCell>
                            <Table.HeaderCell />
                            <Table.HeaderCell>
                                Quantité
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Rendement
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Récompenses<br />cumulées
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Dernière récompense<br />reçue le
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Actions
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            positions.filter(position => ["etheureum", "ethereum_goerli"].includes(position.assetId)).map((position) => (
                                <Table.Row key={position.assetId}>
                                    <Table.Cell>{CryptoLogos[position.currency]}</Table.Cell>
                                    <Table.Cell>
                                        <strong>{position.currency}</strong>
                                        <br />
                                        {position.assetId}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {position.stakedQuantity.toFixed(9)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <span
                                            className={rendement[position.assetId] >= 0 ? "positive" : "negative"}
                                            style={{verticalAlign:"middle", lineHeight:"1", display:"flex", alignItems:"center"}}
                                        >
                                            {rendement[position.assetId]?.toFixed(2)}%&nbsp;
                                            <Icon
                                                style={{ transform:"scale(0.6)", verticalAlign: "middle" }}
                                                className="info circular" />
                                        </span>
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{position.rewardsQuantity.toFixed(9)}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {position.rewardsLastDate && new Date(position.rewardsLastDate).toLocaleString("fr-FR", {
                                            day: "2-digit",
                                            month: "2-digit",
                                            year: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                        })}
                                    </Table.Cell>

                                    <Table.Cell textAlign="center">
                                        <Dropdown
                                            icon='ellipsis vertical'
                                            floating
                                            className='icon'>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    as={Link}
                                                    to={routes.stake(position.assetId)}
                                                >
                                                    Staker
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    as={Link}
                                                    to={routes.unstake(position.assetId)}
                                                    disabled={position.stakedQuantity === 0.0}
                                                >
                                                    Retirer
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
};

export default ActiveStaking;
