import React from 'react';
import { useLocation, BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import {
  Container,
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import Homepage from './pages/Homepage';
import UserInfo from './pages/UserInfo';
import Addresses from './pages/Addresses';
import WithdrawPage from './pages/Withdraw';
import keycloak from './utils/keycloak';
import Synchronizer from './components/Synchronizer';
import Situation from './pages/Situation';
import { UserProvider } from './utils/UserProvider';
import AddAddress from './components/addresses/AddAddress';
import AppMenuService from './components/common/navbar/AppMenuService';
import WaitingActions from './admin-pages/WaitingActions';
import SearchClients from './admin-pages/SearchClients';
import NewStaking from './components/staking/NewStaking';

const RequireAuth = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const isAuthenticated = keycloak.authenticated;
  const location = useLocation();

  if (!initialized) {
    return (
      <Container>
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
      </Container>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to='/' state={{ from: location }} />;
  }


  return (
    <>
      {children}
    </>);
}

const AppRoutes = () => {
  const { routes } = AppMenuService();

  return <Routes>
    <Route path={routes.home} element={<Homepage />} />
    {/* situation */}
    <Route path={routes.wallet} element={<RequireAuth><Situation key="wallet" /></RequireAuth>} />
    <Route path={routes.operations} element={<RequireAuth><Situation key="operations" /></RequireAuth>} />
    <Route path={routes.staking} element={<RequireAuth><Situation key="staking" /></RequireAuth>} />
    <Route path={routes.stake(':assetId')} element={<RequireAuth><NewStaking /></RequireAuth>} />
    <Route path={routes.unstake(':assetId')} element={<RequireAuth><NewStaking /></RequireAuth>} />
    {/* mon espace */}
    <Route path={routes.infos} element={<RequireAuth><UserInfo /></RequireAuth>} />
    <Route path={routes.addresses} element={<RequireAuth><Addresses /></RequireAuth>} />
    <Route path={routes.addAddress} element={<RequireAuth><AddAddress /></RequireAuth>} />
    {/* autres */}
    <Route path={routes.withdraw(':assetId')} element={<RequireAuth><WithdrawPage /></RequireAuth>} />
    {/* admins */}
    <Route path={routes.waitingActions} element={<RequireAuth><WaitingActions /></RequireAuth>} />
    <Route path={routes.searchClients} element={<RequireAuth><SearchClients /></RequireAuth>} />
  </Routes>;
}


const App = () => {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <UserProvider>
        <Synchronizer />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </UserProvider>
    </ReactKeycloakProvider>
  );
}

export default App;