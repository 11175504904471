const STATUSES = {
    PENDING_APPROVAL: "En cours de validation",
    VALIDATED: "Validé",
  };
  
  const getStatusClass = (status) => {
    switch (status) {
      case "VALIDATED":
        return "green";
      case "PENDING_APPROVAL":
        return "orange";
      default:
        return "";
    }
  };

  export const AccountStatusUtils = {
    STATUSES,
    getStatusClass,
}