import { Button, Icon, Menu } from "semantic-ui-react";
import AppMenuService from "./AppMenuService";
import { useRecoilValue } from "recoil";
import { userInfoState } from "../../../states/AppState";

const genderMap = {
    "Monsieur": "M.",
    "Madame": "Mme"
}

export default function TopNavBarLg() {
    const user = useRecoilValue(userInfoState)

    const {
        keycloak,
        handleOnLogin,
        handleOnLogout
    } = AppMenuService();

    return (
        <Menu className="topbar" inverted borderless fixed="top" style={{ position: 'fixed', width: '100%', zIndex: 900 }}>
            {!keycloak.authenticated && (
                <>
                    <Menu.Menu position="right">
                        <Menu.Item style={{ fontWeight: "bold" }}>
                            <Button
                                disabled
                                inverted
                                color="blue"
                                style={{ marginRight: "10px" }}
                            >
                                S'enregistrer
                            </Button>
                            <Button onClick={handleOnLogin} color="blue" inverted>
                                {" "}
                                <Icon name="lock" />
                                Se connecter
                            </Button>
                        </Menu.Item>
                    </Menu.Menu>
                </>
            )}
            {keycloak.authenticated && (
                <>
                    <Menu.Menu position="right">
                        <Menu.Item>
                            <div style={{ fontWeight: "bold", color: 'white' }}>
                                {genderMap[user.gender]} {user.firstName} {user.lastName}
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <Button onClick={handleOnLogout} color='red' inverted>Se déconnecter</Button>
                        </Menu.Item>
                    </Menu.Menu>

                </>
            )}
        </Menu>
    );
}
