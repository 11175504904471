import 'semantic-ui-css/semantic.min.css';
import React, { useEffect, useState } from "react";
import { Modal, Icon, Table, Form, TextArea, Grid, Segment, Button } from "semantic-ui-react";
import { sleepwellApi } from "../../../utils/SleepwellApi";
import { useRecoilValue } from "recoil";
import { userIsConformiteState } from "../../../states/AppState";
import UserUtils from "../../users/UserUtils";
import { Utils } from "../../../utils/Utils";

const getFullName = (user) => `${user.firstName} ${user.lastName}`;


const AdminAccountDetailsModal = ({ account, isOpen, onClose }) => {
    const [copyFeedback, setCopyFeedback] = useState(null);
    const [tier, setTier] = useState(null);
    const [user, setUser] = useState(null);
    const [comment, setComment] = useState('');
    const isConformite = useRecoilValue(userIsConformiteState);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!isOpen) {
            setUser(null);
        } else {
            const fetchUserData = async () => {
                if (!account.clientId) return;
                sleepwellApi.getUser(account.clientId)
                    .then((response) => setUser(response.data))
                    .catch((e) =>
                        console.log("Erreur lors de la recuperation des informations du client"));
            };
            fetchUserData();
        }
    }, [account, isOpen]);

    useEffect(() => {
        const fetchTier = async () => {
            if (account && account.tiersId) {
                sleepwellApi.getTier(account.tiersId)
                    .then((response) => setTier(response.data))
                    .catch((e) => console.error("Error fetching the tier:", e));
            }
        };
        fetchTier();
    }, [account]);


    const handleCopyClick = async (text) => {
        const success = await Utils.copyToClipboard(text);
        if (success) {
            setCopyFeedback('success');
            setTimeout(() => {
                setCopyFeedback(null);
            }, 2000);
        } else {
            setCopyFeedback('error');
            setTimeout(() => setCopyFeedback(null), 2000);
        }
    };

    const handleActionClick = async (action) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await (action === 'reject'
                ? sleepwellApi.rejectAccount(account.id, comment)
                : sleepwellApi.validateAccount(account.id, comment));

            console.log(response);
            onClose();
        } catch (error) {
            console.error(`Erreur lors ${action === 'reject' ? 'du rejet' : 'de la validation'} du compte:`, error);
            console.log(error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    if (!account || !isConformite) return null;

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Modal.Header>
                Détails de l'adresse à controler
                <Icon name="close" link style={{ float: "right" }} onClick={onClose} />
            </Modal.Header>
            <Modal.Content>
                <Table className="reverted">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={6}>Libellé</Table.Cell>
                            <Table.Cell>
                                {account.label}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Type</Table.Cell>
                            <Table.Cell>
                                {account.type}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Adresse</Table.Cell>
                            <Table.Cell>{account.address}&nbsp;
                                <span onClick={() => handleCopyClick(account.address)}>
                                    <i className="copy icon"></i>
                                    {copyFeedback !== null && <span className={copyFeedback === "success" ? "positive" : "negative"}>
                                        {copyFeedback === "success" ? "Texte copié!" : "Erreur!"}
                                    </span>}
                                </span>
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell width={6}>Nom complet</Table.Cell>
                            <Table.Cell>
                                {account.tiersId && tier && getFullName(tier)}
                                {!account.tiersId && user && getFullName(user)}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Pays de résidence</Table.Cell>
                            <Table.Cell>
                                {account.tiersId && tier && tier.country}
                                {!account.tiersId && user && UserUtils.getCountryName(user.country)}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Note conformité</Table.Cell>
                            <Table.Cell>{account.complianceNote}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

            </Modal.Content>
            {isConformite && <>
                <Modal.Header>
                    Commentaire justificatif
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field
                            control={TextArea}
                            label='Commentaire justificatif'
                            value={comment}
                            onChange={(e, { value }) => setComment(value)}
                        />
                        <Grid>
                            <Grid.Column floated='right' width={5}>
                                {error &&
                                    <Segment basic textAlign='right' style={{ paddingBottom: 0, marginBottom: 0 }}>
                                        <span style={{ color: 'red', paddingBottom: 0, marginBottom: 0 }}>
                                            {error.response.data.status + ": " + error.response.data.message}
                                        </span>
                                    </Segment>
                                }
                                <Segment basic textAlign='right'>
                                    <Button loading={isLoading} disabled={isLoading} onClick={() => handleActionClick('reject')}>Rejeter</Button>
                                    <Button primary loading={isLoading} disabled={isLoading} onClick={() => handleActionClick('validate')}>Valider</Button>
                                </Segment>
                            </Grid.Column>

                        </Grid>
                    </Form>

                </Modal.Content>

            </>}
        </Modal>
    );
};

export default AdminAccountDetailsModal;
