import React from 'react';
import { useMediaQuery } from 'react-responsive';

import NavbarLg from './NavBarLg';
import NavbarMb from './NavBarMb';


const AppMenu = () => {
  const isMobile = useMediaQuery({ query: "(max-width:576px)" });

  return (
    <>
      {isMobile ? <NavbarMb /> : <NavbarLg />}
    </>
  );
}

export default AppMenu;