import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import { Divider, Header, Segment } from 'semantic-ui-react';
import { useRecoilValue } from 'recoil';
import {
    userInfoState,
    userPositionsState
} from '../../states/AppState';
import { Utils } from '../../utils/Utils';


const AccountSituation = () => {
    const user = useRecoilValue(userInfoState);
    const positions = useRecoilValue(userPositionsState);

    const totalValue = positions.reduce((sum, position) => {
        return sum + position.estimation;
    }, 0);

    return (
        <Segment className='account-situation' key={user.id}>
            <Header as='h3'>Situation du compte</Header>
            <span className="small-text grey">
                Nº de compte : {user.id} - Ouvert le {
                    new Date(user.createdOn).toLocaleString('fr-FR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                    })}
            </span>
            <Divider />
            <Header as='h3' style={{ marginBottom: "0px", marginTop: "0px" }} className="big-text grey">
                {Utils.formatBalance(totalValue)}
            </Header>
            <span className='small-text grey'>
                Solde estimé
            </span>
        </Segment>
    );
};

export default AccountSituation;