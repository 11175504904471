import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { sleepwellApi } from './utils/SleepwellApi';

const firebaseConfig = {
  apiKey: "AIzaSyAMK7SEDHn-CKH5XhjTwhmbvKqzG1_U1L4",
  authDomain: "prj-bu3-d-p42-1234.firebaseapp.com",
  projectId: "prj-bu3-d-p42-1234",
  storageBucket: "prj-bu3-d-p42-1234.appspot.com",
  messagingSenderId: "503058636532",
  appId: "1:503058636532:web:a7cca5a59c9b856f9048a1"
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const getFcmToken = async () => {
  try {
    const token = await getToken(messaging);
    return token;
  } catch (error) {
    console.error('Error getting FCM token:', error);
    return null;
  }
};

export const requestNotificationPermission = async (userId, deviceTokens) => {
  if (Notification.permission === 'granted') {
    const token = await getFcmToken();
    const exists = deviceTokens?.some(item => item.token === token) ?? false;
    if (userId && token && !exists) {
      sleepwellApi.addUserDeviceToken(userId, token);
    } else {
    }
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(async permission => {
      if (permission === 'granted') {
        const token = await getFcmToken();
        const exists = deviceTokens.some(item => item.token === token);
        if (userId && token && !exists) {
          sleepwellApi.addUserDeviceToken(userId, token);
        }
      }
    });
  }

}
