import { useKeycloak } from '@react-keycloak/web';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const routes = {
  home: "/",
  wallet: '/situation/wallet',
  operations: '/situation/operations',
  staking: '/situation/staking',
  stake(assetId) { return `/situation/staking/stake/${assetId}` },
  unstake(assetId) { return `/situation/staking/unstake/${assetId}` },
  infos: '/my-space/personal-info',
  addresses: '/my-space/addresses',
  addAddress: '/my-space/addresses/add',
  withdraw(assetId) { return `/withdraw/${assetId}` },
  waitingActions: '/admins/waitingActions',
  searchClients: '/admins/searchClients',
};

const AppMenuService = () => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const handleOnSelectMenu = (event, { name }) => {
    console.log('AppMenu', 'handleSelectMenu', name);
    navigate(routes[name]);
  };

  const handleOnLogin = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  const handleOnLogout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  return { routes, keycloak, handleOnSelectMenu, handleOnLogin, handleOnLogout, navigate };
};

export default AppMenuService;