import { useState } from 'react';
import { Container, Image, Menu, Sidebar } from 'semantic-ui-react';
import AppMenuService from './AppMenuService';
import { useRecoilValue } from 'recoil';
import { logoState, userIsConformiteState } from '../../../states/AppState';




function NavbarLg() {
    const logo = useRecoilValue(logoState)
    const { routes, handleOnSelectMenu } = AppMenuService();
    const [activeItem] = useState(window.location.pathname);

    const isConformite = useRecoilValue(userIsConformiteState);

    const ConformiteSection = () => <div className='grey-section'>
        <Menu.Item header>Administration</Menu.Item>
        <Menu.Item className='submenu' onClick={handleOnSelectMenu} name='waitingActions' active={activeItem === routes.waitingActions}>Action en attente</Menu.Item>
        <Menu.Item className='submenu' onClick={handleOnSelectMenu} name='searchClients' active={activeItem === routes.searchClients}>Rercherche clients</Menu.Item>
    </div>;

    const ClientSection = () => <><div className='grey-section'>
        <Menu.Item header>Situation</Menu.Item>
        <Menu.Item className='submenu' onClick={handleOnSelectMenu} name='home' active={activeItem === routes.wallet || activeItem === routes.home}>Portefeuille</Menu.Item>
        <Menu.Item className='submenu' onClick={handleOnSelectMenu} name='operations' active={activeItem === routes.operations}>Historique des opérations</Menu.Item>
        <Menu.Item className='submenu' onClick={handleOnSelectMenu} name='staking' active={activeItem === routes.staking}>Staking</Menu.Item>
    </div>

        <Menu.Item header>Mon Espace</Menu.Item>
        <Menu.Item className='submenu' onClick={handleOnSelectMenu} name='infos' active={activeItem === routes.infos}>Mes données personnelles</Menu.Item>
        <Menu.Item className='submenu' onClick={handleOnSelectMenu} name='addresses' active={activeItem === routes.addresses}>Mes adresses</Menu.Item></>
        ;

    return (
        <>
            <Sidebar as={Menu} inverted vertical animation='push' visible direction='left'>
                <Container className="ui top-menu-items">

                    <Menu.Item style={{ display: 'flex', alignItems: 'center', margin: "0", marginTop: "50px" }}>
                        <Image src={`/assets/images/${logo}`} height="70px" alt="" />
                        {/* <span className='logo-label'>Procapital</span> */}
                    </Menu.Item>

                    <Menu.Item header>Tableau de bord</Menu.Item>
                    {isConformite ? <ConformiteSection /> : <ClientSection />}
                </Container>
            </Sidebar>
        </>
    );
}

export default NavbarLg;