import React from "react";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  Dropdown,
} from "semantic-ui-react";
import { Utils } from "../../utils/Utils";
import { Link } from "react-router-dom";
import AppMenuService from "./navbar/AppMenuService";
import CryptoLogos from "./CyptoLogos";

const CryptoTable = ({ positions }) => {
  const { routes } = AppMenuService();

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell textAlign="center">Crypto</TableHeaderCell>
          <TableHeaderCell> </TableHeaderCell>
          <TableHeaderCell textAlign="center">Quantité<br />disponible</TableHeaderCell>
          <TableHeaderCell textAlign="center">Quantité<br />stakée</TableHeaderCell>
          <TableHeaderCell textAlign="center">Cours</TableHeaderCell>
          <TableHeaderCell textAlign="center">Estimation</TableHeaderCell>
          <TableHeaderCell textAlign="center">
            Actions
          </TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {positions.map((position) => (
          <TableRow key={position.assetId}>
            <TableCell textAlign="center">{CryptoLogos[position.currency]}</TableCell>
            <TableCell>
              <strong>{position.currency}</strong>
              <br />
              {position.assetId}
            </TableCell>
            <TableCell textAlign="center">{position.quantity.toFixed(9)}</TableCell>
            <TableCell textAlign="center">{position.stakedQuantity.toFixed(9)}</TableCell>
            <TableCell textAlign="center">
              {Utils.formatBalance(position.cours)}
              <br />
              <span
                className={position.variation >= 0 ? "positive" : "negative"}
              >
                {Utils.formatNumber(Utils.roundToTwo(position.variation))} %
              </span>
            </TableCell>
            <TableCell textAlign="center">{Utils.formatBalance(position.estimation)}</TableCell>
            <TableCell textAlign="center">
              <Dropdown
                icon='ellipsis vertical'
                floating
                className='icon'>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to={routes.addresses}>
                    Dépôt
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={routes.withdraw(position.assetId)}>
                    Retrait
                  </Dropdown.Item>
                  {position.type === "Ethereum" &&
                    <Dropdown.Item as={Link} to={routes.staking}>
                      Staking
                    </Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CryptoTable;