import axios from 'axios';
import keycloak from './keycloak';

// -- Axios

const instance = axios.create({
  baseURL: process.env.REACT_APP_SLEEPWELL_API_URL || "http://localhost:8080",
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
})

// instance.interceptors.response.use(response => {
//   return response;
// }, function (error) {
//   return Promise.reject(error.response);
// })

instance.interceptors.response.use((response) => response, (error) => {
  if (typeof error.response === 'undefined') {
    alert('A network error occurred. '
      + 'This could be a CORS issue or a dropped internet connection. '
      + 'It is not possible for us to know.');
  }
  return Promise.reject(error);
})

function bearerAuth() {
  return `Bearer ${keycloak.token}`
}

function options() {
  return {
    headers: {
      'Content-type': 'application/json',
      Authorization: bearerAuth()
    }
  };
}

const getUserAccounts = async (userId) => {
  console.log('SleepwellApi', 'getUserAccounts', userId);
  return instance.get(`/api/users/${userId}/accounts`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getUserAccounts', 'error', e);
      throw e;
    });
}

const synchronizeDatabaseWithKeycloak = async (email, firstName, lastName) => {
  console.log('SleepwellApi', 'synchronizeDatabaseWithKeycloak', email);
  return instance.put(
    `/api/users/synchronize?email=${email}&firstName=${firstName}&lastName=${lastName}`,
    null, options())
    .catch((e) => {
      console.log('SleepwellApi', 'synchronizeDatabaseWithKeycloak', 'error', e);
      throw e;
    });
}

const getUserPositions = async (userId) => {
  console.log('SleepwellApi', 'getUserPositions');
  return instance.get(`/api/users/${userId}/positions`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getUserPositions', 'error', e);
      throw e;
    });
}

const getUserTransactions = async (userId) => {
  console.log('SleepwellApi', 'getUserTransactions');
  return instance.get(`/api/users/${userId}/transactions`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getUserTransactions', 'error', e);
      throw e;
    });
}

const getUser = async (clientId) => {
  console.log('SleepwellApi', 'getUser');
  return instance.get(`/api/users/${clientId}`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getUser', 'error', e);
      throw e;
    });
}

const getUserFromEmail = async (email) => {
  console.log('SleepwellApi', 'getUserFromEmail');
  return instance.get(`/api/users/email/${email}`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getUserFromEmail', 'error', e);
      throw e;
    });
}

const getCyrptoPrices = async () => {
  console.log('SleepwellApi', 'getCyrptoPrices');
  try {
    var { status, data } = await instance.get(`/api/cryptos/price`, options());
    if (status < 200 || status > 299) {
      throw new Error(`Status is ${status}`);
    }
    const {
      bitcoin: { eur: bitcoinPrice, eur_24h_change: bitcoinChange },
      ethereum: { eur: ethereumPrice, eur_24h_change: ethereumChange },
      'matic-network': { eur: polygonPrice, eur_24h_change: polygonChange },
    } = data;

    return {
      bitcoin: bitcoinPrice,
      bitcoin_24h_change: bitcoinChange,
      bitcoin_testnet: bitcoinPrice,
      bitcoin_testnet_24h_change: bitcoinChange,
      ethereum: ethereumPrice,
      ethereum_24h_change: ethereumChange,
      ethereum_goerli: ethereumPrice,
      ethereum_goerli_24h_change: ethereumChange,
      matic_network: polygonPrice,
      matic_network_24h_change: polygonChange,
    };
  } catch (error) {
    console.log('SleepwellApi', 'getCyrptoPrices', 'error', error);
    return false;
  }
}

const getUserDeviceTokens = async (userId) => {
  console.log('SleepwellApi', 'getUserDeviceTokens');
  return instance.get(`/api/users/${userId}/tokens`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getUserDeviceTokens', 'error', e);
      throw e;
    });
}

const addUserDeviceToken = async (userId, deviceToken) => {
  console.log('SleepwellApi', 'addUserDeviceToken', userId);
  const options = {
    headers: {
      'Content-type': 'text/plain',
      Authorization: bearerAuth()
    }
  };
  return instance.post(`/api/users/${userId}/tokens`, deviceToken, options)
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'addUserDeviceToken', 'error', e);
      throw e;
    });
}

const withdraw = async (body) => {
  console.log('SleepwellApi', 'withdraw');
  return instance.post(`/api/transactions/withdraw`, body, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'withdraw', 'error', e);
      throw e;//new Error(`Suite à une erreur technique, votre demande de retrait ne peut pas être traitée actuellement.`);
    });
}

const getAllFees = async (body) => {
  console.log('SleepwellApi', 'getAllFees');
  return instance.post(`/api/transactions/get-fees`, body, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getAllFees', 'error', e);
      throw e;
    });
}

const getAvailableQuantity = async (userId, assetId) => {
  console.log('SleepwellApi', 'getAvailableQuantity', userId, assetId);
  const options = {
    params: {
      assetId: assetId
    },
    headers: {
      'Content-type': 'application/json',
      Authorization: bearerAuth()
    }
  };
  return instance.get(`/api/users/${userId}/getAvailableQuantity`, options)
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getAvailableQuantity', 'error', e);
      throw e;
    });
}

const addAccount = async (userId, body) => {
  console.log('SleepwellApi', 'addAccount');
  return instance.post(`/api/users/${userId}/accounts`, body, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'addAccount', 'error', e);
      throw e;
    });
}

const getTier = async (tierId) => {
  console.log('SleepwellApi', 'getTier', tierId);
  return instance.get(`/api/tiers/${tierId}`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      console.log('SleepwellApi', 'getTier', response);
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getTier', 'error', e);
      throw e;
    });
}

const deactivateAddress = async (account) => {
  console.log('SleepwellApi', 'deactivateAddress', account);
  return instance.put(`/api/accounts/deactivate/${account}`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'deactivateAddress', 'error', e);
      throw e;
    });
};

const stake = async (positionId, quantity) => {
  console.log('SleepwellApi', 'stake', quantity);
  return instance.post(`/api/positions/${positionId}/stake`, quantity, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'stake', 'error', e);
      throw e;
    });
};

const unstake = async (positionId, quantity) => {
  console.log('SleepwellApi', 'stake', quantity);
  return instance.post(`/api/positions/${positionId}/unstake`, quantity, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'stake', 'error', e);
      throw e;
    });
};

const getGrossApy = async (assetId) => {
  console.log('SleepwellApi', 'getGrossApy', assetId);
  return instance.get(`/api/positions/gross-apy?asset=${assetId}`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getGrossApy', 'error', e);
      throw e;
    });
};


//admins
const getAllUsers = async (token) => {
  console.log('SleepwellApi', 'getAllUsers');
  return instance.get(`/api/users`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getAllUsers', 'error', e);
      throw e;
    });
}

const getAllTransactions = async () => {
  console.log('SleepwellApi', 'getAllTransactions');
  return instance.get(`/api/transactions`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getAllTransactions', 'error', e);
      throw e;
    });
}

const getPendingTransactions = async () => {
  console.log('SleepwellApi', 'getPendingTransactions');
  return instance.get(`/api/transactions/get-pending-transactions`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getPendingTransactions', 'error', e);
      throw e;
    });
}

const getAllPositions = async (params) => {
  console.log('SleepwellApi', 'getAllPositions');
  const options = {
    params,
    headers: {
      'Content-type': 'application/json',
      Authorization: bearerAuth()
    }
  };
  return instance.get(`/api/positions`, options)
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getAllPositions', 'error', e);
      throw e;
    });
}

const getAllAccounts = async (params) => {
  console.log('SleepwellApi', 'getAllAccounts');
  const options = {
    params,
    headers: {
      'Content-type': 'application/json',
      Authorization: bearerAuth()
    }
  };
  return instance.get(`/api/accounts`, options)
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getAllAccounts', 'error', e);
      throw e;
    });
}

const getPendingAccounts = async () => {
  console.log('SleepwellApi', 'getPendingAccounts');
  return instance.get(`/api/accounts/get-pending-accounts`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getPendingAccounts', 'error', e);
      throw e;
    });
}

const validateTransaction = async (id, body) => {
  console.log('SleepwellApi', 'validateTransaction');
  const options = {
    headers: {
      'Content-type': 'text/plain',
      Authorization: bearerAuth()
    }
  };
  return instance.post(`/api/transactions/${id}/validate`, body, options)
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'validateTransaction', 'error', e);
      throw e;
    });
}

const rejectTransaction = async (id, body) => {
  console.log('SleepwellApi', 'rejectTransaction');
  const options = {
    headers: {
      'Content-type': 'text/plain',
      Authorization: bearerAuth()
    }
  };
  return instance.post(`/api/transactions/${id}/reject`, body, options)
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'rejectTransaction', 'error', e);
      throw e;
    });
}

const validateAccount = async (id, body) => {
  console.log('SleepwellApi', 'validateAccount');
  const options = {
    headers: {
      'Content-type': 'text/plain',
      Authorization: bearerAuth()
    }
  };
  return instance.post(`/api/accounts/${id}/validate`, body, options)
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'validateAccount', 'error', e);
      throw e;
    });
}

const rejectAccount = async (id, body) => {
  console.log('SleepwellApi', 'rejectAccount');
  const options = {
    headers: {
      'Content-type': 'text/plain',
      Authorization: bearerAuth()
    }
  };
  return instance.post(`/api/accounts/${id}/reject`, body, options)
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'rejectAccount', 'error', e);
      throw e;
    });
}

const getAllClients = async (params) => {
  console.log('SleepwellApi', 'getAllClients');
  const options = {
    params,
    headers: {
      'Content-type': 'application/json',
      Authorization: bearerAuth()
    }
  };
  return instance.get(`/api/users`, options)
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getAllClients', 'error', e);
      throw e;
    });
}

const getMinWithdrawAmount = async (assetId) => {
  console.log('SleepwellApi', 'getMinWithdrawAmount');
  return instance.get(`/api/transactions/get-min-withdraw-amount?assetId=${assetId}`, options())
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        throw new Error(`Status is ${response.status}`);
      }
      return response;
    })
    .catch((e) => {
      console.log('SleepwellApi', 'getMinWithdrawAmount', 'error', e);
      throw e;
    });
}


export const sleepwellApi = {
  getUserAccounts, // 2

  // -------------------------------------------
  // From UserController
  synchronizeDatabaseWithKeycloak, // 8
  //--------------------------------------------
  // positions
  getUserPositions,
  getUserTransactions,

  //users
  getUser,
  getUserFromEmail,

  // crypto
  getCyrptoPrices,

  //tokens
  getUserDeviceTokens,
  addUserDeviceToken,

  //withdraw
  withdraw,
  getAllFees,
  getAvailableQuantity,
  getMinWithdrawAmount,

  // accounts
  addAccount,
  deactivateAddress,

  // tiers
  getTier,

  // staking
  stake,
  unstake,
  getGrossApy,

  // admin
  getAllUsers,
  getAllPositions,
  getAllTransactions,
  getPendingTransactions,
  getAllAccounts,
  getPendingAccounts,
  validateTransaction,
  rejectTransaction,
  validateAccount,
  rejectAccount,
  getAllClients,
}




