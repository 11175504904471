import 'semantic-ui-css/semantic.min.css';
import React, { useEffect } from 'react';
import {
  Container,
  Dimmer,
  Loader,
  Image,
} from 'semantic-ui-react';
import { useKeycloak } from '@react-keycloak/web';
import TopNavBarLgHome from '../components/common/navbar/TopNavBarLgHome';
import Situation from './Situation';
import { userIsConformiteState } from '../states/AppState';
import { useRecoilValue } from 'recoil';
import AppMenuService from '../components/common/navbar/AppMenuService';

const HomePage = () => {
  const { keycloak, initialized } = useKeycloak();
  const isConformite = useRecoilValue(userIsConformiteState);
  const { navigate, routes } = AppMenuService();

  useEffect(() => {
    if (isConformite) {
      navigate(routes.waitingActions);
    }
  }, [isConformite, navigate, routes]);


  if (!initialized) {
    return (
      <Container>
        <Dimmer active>
          <Loader content='Loading' />
        </Dimmer>
      </Container>
    );
  }

  if (isConformite) {
    navigate(routes.waitingActions);
  }

  return (
    <>
      {!keycloak.authenticated &&
        <>
          <TopNavBarLgHome />
          <Container className='Page'>
            <Container className='toz' style={{ marginTop: '50px' }}>
              <Container className='Image'>
                <Image src='/assets/images/crypto_home_logo_2.jpeg' fluid />
                {/* Crypto Vault Homepage Content */}
                <Container className='CryptoVaultPresentation'>
                  <h2>Le coffre-fort pour vos Cryptomonnaies</h2>
                  <p>
                    Bienvenue dans le monde de la sécurité maximale pour vos précieuses cryptomonnaies ! Notre service de coffre-fort de crypto offre une solution inégalée pour stocker et protéger vos actifs numériques de manière fiable et robuste.
                  </p>
                  <p>
                    <strong>Caractéristiques Principales :</strong>
                  </p>
                  <ol>
                    <li>
                      <strong>Sécurité Maximale :</strong> Vos cryptomonnaies sont protégées par des protocoles de sécurité avancés, y compris la cryptographie de pointe et une architecture à plusieurs niveaux.
                    </li>
                    <li>
                      <strong>Stockage Hors Ligne (Cold Storage) :</strong> Les clés privées sont stockées de manière hors ligne, éliminant ainsi la menace des attaques en ligne.
                    </li>
                    <li>
                      <strong>Facilité d'Utilisation :</strong> Une interface utilisateur conviviale vous permet de gérer vos actifs en toute simplicité, même si vous êtes novice dans le domaine des cryptomonnaies.
                    </li>
                    {/* ... Ajoutez d'autres caractéristiques si nécessaire */}
                  </ol>
                  <p>
                    <strong>Pourquoi Choisir le coffre-fort de crypto ?</strong>
                  </p>
                  <ul>
                    <li>
                      <strong>Sécurité Inégalée :</strong> Nous comprenons l'importance de la sécurité dans le monde des cryptomonnaies. Notre coffre-fort de crypto est conçue pour être aussi sûre que possible.
                    </li>
                    <li>
                      <strong>Gestion Intuitive :</strong> Nous croyons en la simplicité. Notre interface intuitive vous permet de gérer vos actifs sans tracas.
                    </li>
                    <li>
                      <strong>Service Personnalisé :</strong> Notre équipe est toujours là pour vous aider. Que vous ayez des questions sur la sécurité ou que vous ayez besoin d'assistance, nous sommes là pour vous.
                    </li>
                    {/* ... Ajoutez d'autres points forts si nécessaire */}
                  </ul>
                  <p>
                    Protégez vos cryptomonnaies avec le coffre-fort de crypto - là où la sécurité rencontre la simplicité !
                  </p>
                </Container>
              </Container>
            </Container>
          </Container>
        </>}
      {keycloak.authenticated &&
        <Situation />}
    </>
  );
}

export default HomePage;
