import { Button, Icon, Menu, Image } from "semantic-ui-react";
import AppMenuService from "./AppMenuService";
import { useRecoilValue } from "recoil";
import { userInfoState } from "../../../states/AppState";

const genderMap = {
  "Monsieur": "M.",
  "Madame": "Mme"
}

export default function TopNavBarLg() {
  const user = useRecoilValue(userInfoState)

  const {
    keycloak,
    handleOnLogin,
    handleOnLogout
  } = AppMenuService();

  return (
    <Menu className="topbarhome" inverted borderless fixed="top" style={{ position: 'fixed', width: '100%', zIndex: 900 }}>
      <Menu.Item>
        <Image src="/assets/images/procapital-icon.png" size="small" />
      </Menu.Item>

      <Menu.Item position="center" style={{ color: 'white', fontSize: '2.5em', width: '60%', paddingLeft: '20%' }}>
        SleepWell : votre coffre-fort de crypto
      </Menu.Item>

      {!keycloak.authenticated && (
        <Menu.Menu position="right">
          <Menu.Item style={{ fontWeight: "bold" }}>
            <Button disabled inverted color="blue">
              S'enregistrer
            </Button>
            <Button onClick={handleOnLogin} color="blue" inverted>
              {" "}
              <Icon name="lock" />
              Se connecter
            </Button>
          </Menu.Item>
        </Menu.Menu>
      )}

      {keycloak.authenticated && (
        <Menu.Menu position="right">
          <Menu.Item>
            <div style={{ fontWeight: "bold", color: 'white' }}>
              {genderMap[user.gender]} {user.firstName} {user.lastName}
            </div>
          </Menu.Item>
          <Menu.Item>
            <Button onClick={handleOnLogout} color='red' inverted>Se déconnecter</Button>
          </Menu.Item>
        </Menu.Menu>
      )}
    </Menu>
  );
}
