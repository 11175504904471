function formatBalance(amount) {
  const fixedAmount = roundToTwo(amount);
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(fixedAmount);
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

function formatNumber(n) {
  if (n > 0) {
    return `+ ${n}`;
  } else if (n < 0) {
    return `- ${Math.abs(n)}`;
  } else {
    return `${n}`;
  }
}

const copyToClipboard = (text) => {
  return navigator.clipboard.writeText(text).then(
    () => {
      console.log('Text copied to clipboard');
      return true;
    },
    (err) => {
      console.error('Could not copy text: ', err);
      return false;
    }
  );
};


export const Utils = {
  formatBalance,
  roundToTwo,
  copyToClipboard,
  formatNumber
};