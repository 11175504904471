import 'semantic-ui-css/semantic.min.css';
import React, { useCallback } from 'react';
import {
    Container,
    Dimmer,
    Loader,
    Grid,
} from 'semantic-ui-react';
import { useKeycloak } from '@react-keycloak/web';

import AppMenu from '../components/common/navbar/AppMenu';
import { userAccountsState, userInfoState } from '../states/AppState';
import ListAddresses from '../components/addresses/ListAddresses';
import TopNavBarLg from '../components/common/navbar/TopNavBarLg';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { sleepwellApi } from '../utils/SleepwellApi';


const Addresses = () => {
    const user = useRecoilValue(userInfoState);
    const setUserAccounts = useSetRecoilState(userAccountsState);
    const { initialized } = useKeycloak();


    const getUserAccountsFromUserid = useCallback(() => {
        if (initialized === true && user != null && user.id != null) {
            console.log('UserAccounts', 'getUserAccountsFromUserid()');
            sleepwellApi.getUserAccounts(user.id).then(response => {
                console.log('UserAccounts', 'getUserAccountsFromUserid()', response);
                if (response.data === false) {
                    return false;
                }
                setUserAccounts(response.data);
            })
        }
    }, [initialized, user, setUserAccounts]);


    if (!initialized) {
        return (
            <Container>
                <Dimmer active>
                    <Loader content='Loading' />
                </Dimmer>
            </Container>
        );
    }

    getUserAccountsFromUserid(user.id);

    return (
        <>
            <div style={{ height: '100vh' }}>
                <TopNavBarLg />
                <Grid style={{ height: 'calc(100vh - 50px)', margin: 0 }}>
                    <Grid.Column width={3}>
                        <AppMenu />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <div style={{ overflowY: 'auto', padding: '20px' }}>
                            <ListAddresses />
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
        </>
    );
}

export default Addresses;
