import React from 'react';
import { Divider, Header, Segment } from 'semantic-ui-react';
import { useRecoilValue } from 'recoil';
import { userPositionsState } from '../../states/AppState';
import CryptoTable from '../common/CryptoTable';
import CryptoDoughnut from '../common/CryptoDoughnut';


const UserListPositions = () => {
    const positions = useRecoilValue(userPositionsState);


    const totalEstimation = positions.reduce((sum, position) => sum + position.quantity * position.cours, 0);

    const doughnutData = {
        labels: positions.map(position => position.assetId),
        datasets: [
            {
                data: positions.map(position => (totalEstimation === 0 ? 0 : (position.quantity * position.cours) / totalEstimation)),
                backgroundColor: ["#FF9F40", "#5388D8", "#8247e5"],
                hoverBackgroundColor: ["#FF9F40", "#5388D8", "#8247e5"]
            }
        ]
    };

    return (
        <div>
            <Segment className="positions">
                <Header as='h3'>Évaluation du portefeuille</Header>
                <Divider />
                <CryptoTable positions={positions} />
            </Segment>
            <Segment className="crypto-donut">
                <Header as='h3'>Répartition du portefeuille</Header>
                <Divider />
                <CryptoDoughnut data={doughnutData} />
            </Segment>
        </div>
    );
}
export default UserListPositions;