import { Button, Checkbox, Dimmer, Divider, Dropdown, Form, Grid, Header, Input, Loader } from "semantic-ui-react";
import { getAllCountries, getCountryNameByAlpha3 } from 'country-locale-map';
import AppMenuService from "../common/navbar/AppMenuService";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const countriesList = getAllCountries().map(country => ({
    key: country.alpha3,
    value: country.alpha3,
    text: country.name
}));

const Step1 = ({ label, address, cryptoOptions, type, handleChange, goToStep, alreadyExists }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(alreadyExists);

    useEffect(() => {
        setIsButtonDisabled(alreadyExists);
    }, [alreadyExists]);

    return (<>
        <Grid>
            <Grid.Row columns={1}>
                <Grid.Column>
                    <Header>
                        Données d’identification de l’adresse
                    </Header>
                    <Divider />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={2} verticalAlign="middle">
                    <label>Libellé</label>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Form.Field>
                        <Input
                            type="text"
                            id="label"
                            name="label"
                            value={label}
                            onChange={handleChange}
                            required
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
                <Grid.Column width={2} verticalAlign="middle">
                    <label>Adresse</label>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Form.Field>
                        <Input
                            type="text"
                            id="address"
                            name="address"
                            value={address}
                            onChange={handleChange}
                            required
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
                <Grid.Column width={2} verticalAlign="middle">
                    <label>Réseau</label>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Form.Field>
                        <Dropdown
                            name="type"
                            selection
                            options={cryptoOptions}
                            value={type}
                            onChange={handleChange}
                            required
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
            {alreadyExists && (
                <div style={{ color: 'red', marginTop: '8px' }}>
                    "Cette adresse est déjà présente dans votre liste blanche."
                </div>
            )}
        </Grid>
        <Grid>
            <Grid.Row centered>
                <Grid.Column width={6}>
                    <Button
                        primary
                        fluid
                        disabled={isButtonDisabled}
                        onClick={() => goToStep(2)}>Continuer</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>

    </>);
}

const Step2 = ({ isClientsAccount, firstname, lastname, country, handleChange, goToStep }) => {
    return (<>
        <Grid>

            <Grid.Row columns={1}>
                <Grid.Column>
                    <Header>
                        Identité du titulaire de l’adresse
                    </Header>
                    <Divider />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={4} verticalAlign="middle">
                    <label>Ce portefeuille m'appartient</label>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Checkbox
                        name="isClientsAccount"
                        toggle
                        checked={isClientsAccount}
                        onChange={(e, data) => handleChange(e, { name: "isClientsAccount", value: data.checked })}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={4} verticalAlign="middle">
                    <label>Prénom</label>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Form.Field>
                        <Input
                            type="text"
                            name="firstname"
                            value={firstname}
                            onChange={handleChange}
                            required
                            disabled={isClientsAccount}
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
                <Grid.Column width={4} verticalAlign="middle">
                    <label>Nom</label>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Form.Field>
                        <Input
                            type="text"
                            name="lastname"
                            value={lastname}
                            onChange={handleChange}
                            required
                            disabled={isClientsAccount}
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
                <Grid.Column width={4} verticalAlign="middle">
                    <label>Pays de résidence</label>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Form.Field>
                        <Dropdown
                            placeholder='Pays de résidence'
                            name="country"
                            options={countriesList}
                            value={country}
                            onChange={(e, { name, value }) => handleChange(e, { name, value })}
                            required
                            scrolling
                            search
                            selection
                            disabled={isClientsAccount}
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <Grid>
            <Grid.Row centered>
                <Grid.Column width={6}>
                    <Button primary onClick={() => goToStep(1)}>Retour</Button>
                    <Button primary onClick={() => goToStep(3)}>Continuer</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </>);
}

const Step3 = ({ label, address, type, isClientsAccount, user, firstname, lastname, country, goToStep, handleChange, isModalOpen, handleModal, loading }) => {
    return (
        <>
            <div>Veuillez vérifier les informations puis cliquer sur le bouton "Confirmer"</div>
            <Grid style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header>
                            Données d'identification de l'adresse
                        </Header>
                        <Divider />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column width={2}><strong>Libellé :</strong></Grid.Column>
                    <Grid.Column width={4}>{label}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Adresse :</strong></Grid.Column>
                    <Grid.Column width={4}>{address}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Réseau :</strong></Grid.Column>
                    <Grid.Column width={4}>{type}</Grid.Column>
                </Grid.Row>
                <br /><br /><br />
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header>
                            Données d'identification du titulaire
                        </Header>
                        <Divider />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Prénom :</strong></Grid.Column>
                    <Grid.Column width={4}>{isClientsAccount === true ? user.firstName : firstname}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Nom :</strong></Grid.Column>
                    <Grid.Column width={4}>{isClientsAccount === true ? user.lastName : lastname}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Pays de résidence :</strong></Grid.Column>
                    <Grid.Column width={4} verticalAlign="middle">{getCountryNameByAlpha3(isClientsAccount === true ? user.country : country)}</Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid>
                <Grid.Row centered>
                    <Grid.Column width={6}>
                        <Button primary onClick={() => goToStep(2)}>Retour</Button>
                        <Button primary onClick={() => handleModal(true)}>Confirmer</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {loading &&
                <div>
                    <Dimmer active>
                        <Loader content='En cours...' />
                    </Dimmer>
                </div>
            }
        </>
    );
}

const Step4 = ({ apiResponse, isClientsAccount, user, goToStep, responseError }) => {
    const { routes } = AppMenuService();
    if (responseError)
        return (<>
            <div style={{ color: 'red', marginTop: '8px' }}>
                {responseError.response.data.status} : {responseError.response.data.message || "erreur inconnu"}
            </div>
            <Button primary onClick={() => goToStep(1)}>Retour</Button>
        </>);

    return (
        <>
            <div>{apiResponse.data.status === "PENDING_COMPLIANCE_APPROVAL"
                ? "L’adresse nécessite d’être validée par nos services internes."
                : "L'adresse a été rajoutée à la liste de vos adresses externes autorisées."}
            </div>
            <Grid style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header>
                            Données d'identification de l'adresse
                        </Header>
                        <Divider />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column width={2}><strong>Libellé :</strong></Grid.Column>
                    <Grid.Column width={4}>{apiResponse.data.label}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Adresse :</strong></Grid.Column>
                    <Grid.Column width={4}>{apiResponse.data.address}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Réseau :</strong></Grid.Column>
                    <Grid.Column width={4}>{apiResponse.data.type}</Grid.Column>
                </Grid.Row>
                <br /><br /><br />
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header>
                            Données d'identification du titulaire
                        </Header>
                        <Divider />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Prénom :</strong></Grid.Column>
                    <Grid.Column width={4}>{isClientsAccount === true ? user.firstName : apiResponse.data.tier.firstname}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Nom :</strong></Grid.Column>
                    <Grid.Column width={4}>{isClientsAccount === true ? user.lastName : apiResponse.data.tier.lastname}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Pays de résidence :</strong></Grid.Column>
                    <Grid.Column width={4} verticalAlign="middle">{getCountryNameByAlpha3(isClientsAccount === true ? user.country : apiResponse.data.tier.country)}</Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid>
                <Grid.Row centered>
                    <Grid.Column width={6}>
                        <Link to={routes.addresses}>
                            <Button primary>Retour vers "Mes adresses"</Button>
                        </Link>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}


const AddAddressSteps = {
    Step1,
    Step2,
    Step3,
    Step4
}

export default AddAddressSteps;
