import React from "react";
import { Divider, Header, Segment } from "semantic-ui-react";
import { useRecoilValue } from "recoil";

import { userInfoState } from "../../states/AppState";
import UserUtils from "./UserUtils";


const UserDetail = () => {
  const user = useRecoilValue(userInfoState);

  return (
    <div>
      <Segment className="userDetail" key={user.id}>
        <Header as='h3'>Mes données personnelles</Header>
        <Divider />
        <div>
          <strong>Nº de compte : </strong>
          {user.id} <br />
          <br />
          <strong>Civilité prénom nom : </strong>
          {UserUtils.getGender(user.gender)} {user.firstName} {user.lastName} <br />
          <br />
          <strong>Adresse postale : </strong>
          {user.address} {user.postalCode} {user.city} <br />
          <br />
          <strong>Pays de résidence : </strong>
          {UserUtils.getCountryName(user.country)} <br />
          <br />
          <strong>Adresse email : </strong>
          {user.email} <br />
          <br />
          <strong>Nº de téléphone : </strong>
          {user.phoneNumber} <br />
        </div>
      </Segment>
    </div>
  );
};
export default UserDetail;
