import React from "react";
import { Doughnut } from "react-chartjs-2";
// eslint-disable-next-line
import ChartJS from 'chart.js/auto';


const CryptoDoughnut = ({ data }) => {
  let total = data.datasets[0].data.reduce((a, b) => a + b, 0);
  const newData = {
    ...data,
    labels: data.labels.map((label, i) => `${label}: ${total === 0 ? 0 : ((data.datasets[0].data[i] / total) * 100).toFixed(2)}%`)
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'left',
      },
    },
  };

  return (
    <div className="doughnut-container">
      <Doughnut data={newData} options={options} />
    </div>
  );
};

export default CryptoDoughnut;
