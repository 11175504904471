import { useEffect, useState } from "react";
import { Button, Card, Form, Grid, Container, Dimmer, Loader } from "semantic-ui-react";

const Step1 = ({ amount, handleAmountChange, availableQuantity, formError, dest, options, handleDestChange, goToStep }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(!!formError);

    useEffect(() => {
      setIsButtonDisabled(!!formError); // Update button state on error changes
    }, [formError]);
    
    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={4} verticalAlign="middle">
                        <label className='left-aligned-label'>Destinataire</label>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Form.Field>
                            <Form.Select
                                options={options}
                                value={dest}
                                onChange={handleDestChange}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={4} verticalAlign="middle">
                        <label className='left-aligned-label'>Quantité</label>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Form.Field>
                            <input
                                type='number'
                                value={amount}
                                onChange={handleAmountChange}
                            />
                            {availableQuantity && (
                                <div style={{ marginTop: '8px', color: "grey" }}>
                                    Quantité disponible: {availableQuantity}
                                </div>
                            )}
                            {formError && (
                                <div style={{ color: 'red', marginTop: '8px' }}>
                                    {formError}
                                </div>
                            )}
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid>
                <Grid.Row centered>
                    <Grid.Column width={8}>
                        <Button 
                        primary 
                        fluid 
                        disabled={isButtonDisabled}
                        onClick={() => goToStep(2)}>Continuer</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

const Step2 = ({ currency, selectedSpeed, handleSpeedSelect, transferSpeeds, goToStep, isFetchingFees, fetchingFeesError }) => {
    return (
        <>
            <Form.Field className='transfer-speed-section'>
                <label>Frais réseau</label>
                <div style={{ marginBottom: '8px', color: "grey" }}>
                    Les frais de réseau sont déterminés par l'occupation du réseau
                </div>

                <Card.Group>
                    {transferSpeeds.map(option => (
                        <Card
                            key={option.speed}
                            className={selectedSpeed === option.speed ? 'selected-speed' : ''}
                            color={selectedSpeed === option.speed ? 'blue' : null}
                            onClick={() => handleSpeedSelect(option.speed)}
                        >
                            <Card.Content>
                                <Card.Header>{option.label}  ≈ {option.time}</Card.Header>
                                <Card.Description>
                                    {isFetchingFees || !option.equivalent ? (
                                        <Dimmer active inverted>
                                            <Loader size="small" active inline />
                                        </Dimmer>
                                    ) : (
                                        `≈ ${option.equivalent.toFixed(2)} € | ${option.fees.toFixed(8)} ${currency}`
                                    )}
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
            </Form.Field>
            {fetchingFeesError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                    {fetchingFeesError}
                </div>
            )}
            <Grid>
                <Grid.Row centered>
                    <Grid.Column width={6}>
                        <Button primary onClick={() => goToStep(1)}>Retour</Button>
                        <Button primary onClick={() => goToStep(3)}
                            disabled={selectedSpeed === null || fetchingFeesError !== null}
                        >Continuer</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

const Step3 = ({ goToStep, amount, selectedSpeed, transferSpeeds, dest, options, cryptoPrice, loading, handleModal }) => {
    const selectedSpeedOption = transferSpeeds.find(speed => speed.speed === selectedSpeed);
    const selectedDestOption = options.find(opt => opt.value === dest);
    const receivedAmount = parseFloat(amount) - selectedSpeedOption.fees;

    return (
        <Container>
            <div>Veuillez vérifier les informations puis cliquer sur le bouton "Retirer maintenant"</div>
            <Grid style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column width={2}><strong>Destinataire</strong></Grid.Column>
                    <Grid.Column width={4}>{selectedDestOption.text}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Quantité</strong></Grid.Column>
                    <Grid.Column width={4}>{amount}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <strong>Frais réseau</strong>
                        <div style={{ fontSize: '10px', fontWeight: '400', lineHeight: '15px', color: 'grey' }}>Mode {selectedSpeedOption.label}</div>
                    </Grid.Column>
                    <Grid.Column width={4}>{selectedSpeedOption.fees} ≈ {selectedSpeedOption.equivalent.toFixed(2)} €</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Montant reçu</strong></Grid.Column>
                    <Grid.Column width={4}>{receivedAmount.toFixed(6)} ≈ {(receivedAmount * cryptoPrice).toFixed(2)} €</Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid>
                <Grid.Row centered>
                    <Grid.Column width={6}>
                        <Button primary onClick={() => goToStep(2)}>Retour</Button>
                        <Button primary onClick={() => handleModal(true)}>Retirer maintenant</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {loading &&
                <div>
                    <Dimmer active>
                        <Loader content='En cours...' />
                    </Dimmer>
                </div>
            }
        </Container>
    );
};

const Step4 = ({ goToStep, apiResponse, cryptoPrice, responseError }) => {
    console.log(responseError);
    if (responseError != null) {
        return (
            <>
                <div style={{ color: 'red', marginTop: '8px' }}>
                    {responseError.response.data.status} : {responseError.response.data.message || "erreur inconnu"}
                </div>
                <Button primary onClick={() => goToStep(1)}>Retour</Button>
            </>
        );
    }
    return (
        <Container>
            <div>Votre demande de retrait est en cours de traitement sur la blockchain.</div>
            <br /><br />
            <div>Vous pouvez suivre le status du retrait dans l'historique des opérations.</div>
            <br /><br />
            <div>Rappel des informations:</div>
            <Grid style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column width={2}><strong>Destinataire</strong></Grid.Column>
                    <Grid.Column width={4}>{apiResponse.recipient}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Quantité</strong></Grid.Column>
                    <Grid.Column width={4}>{apiResponse.amount}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}><strong>Montant reçu</strong></Grid.Column>
                    <Grid.Column width={4}>{apiResponse.amount.toFixed(6)} ≈ {(apiResponse.amount * cryptoPrice).toFixed(2)} €</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

const WithdrawSteps = {
    Step1,
    Step2,
    Step3,
    Step4
};

export default WithdrawSteps;
