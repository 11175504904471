const TYPES = {
    SEND: "Retrait",
    RECEIVE: "Dépot",
    STAKING: "Staking client",
    UNSTAKING: "Retrait staking client",
    STAKING_REWARDS: "Récompenses clientes",
    CREATE_NODE: "Création d’un node de staking",
};

const STATUSES = {
    PENDING_APPROVAL: "Attente validation opérateur",
    PENDING_REG_APPROVAL: "Attente validation conformité",
    APPROVED: "En cours de traitement",
    SIGNED: "En cours de traitement",
    SUBMITTED: "En cours de traitement",
    CONFIRMED: "Validée",
    DROPPED: "Rejetée",
    FAILED: "Rejetée",
    REJECTED: "Rejetée",
    PENDING_ADDRESS_APPROVAL: "Attente ajout adresse externe"
};

const getStatusClass = (status) => {
    switch (status) {
        case "CONFIRMED":
            return "green";
        case "PENDING_APPROVAL":
        case "PENDING_REG_APPROVAL":
        case "PENDING_ADDRESS_APPROVAL":
        case "APPROVED":
        case "SIGNED":
        case "SUBMITTED":
            return "orange";
        default:
            return "";
    }
};

const getTxUrl = (assetId, txHash) => {
    switch (assetId) {
        case 'bitcoin':
            return `https://live.blockcypher.com/btc/tx/${txHash}/`;
        case 'bitcoin_testnet':
            return `https://live.blockcypher.com/btc-testnet/tx/${txHash}/`;
        case 'ethereum':
            return `https://etherscan.io/tx/${txHash}`;
        case 'ethereum_goerli':
            return `https://goerli.etherscan.io/tx/${txHash}`;
        case 'matic_network':
            return `https://polygonscan.com/tx/${txHash}`;
        default:
            return '#';
    }
}


const TransactionUtils = {
    TYPES,
    STATUSES,
    getStatusClass,
    getTxUrl
};

export default TransactionUtils;