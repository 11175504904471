import 'semantic-ui-css/semantic.min.css';
import React, { useState } from 'react';
import TopNavBarLg from '../common/navbar/TopNavBarLg';
import { Divider, Form, Grid, Header, Segment } from 'semantic-ui-react';
import AppMenu from '../common/navbar/AppMenu';
import AddAddressSteps from './AddAddressSteps';
import { sleepwellApi } from '../../utils/SleepwellApi';
import { useRecoilValue } from 'recoil';
import { userAccountsState, userInfoState } from '../../states/AppState';
import PasswordOtpModal from '../common/PasswordOtpModal';


const AddForm = () => {
    const user = useRecoilValue(userInfoState);
    const [currentStep, setCurrentStep] = useState(1);

    const [address, setAddress] = useState(null);
    const [label, setLabel] = useState(null);
    const [type, setType] = useState('Bitcoin');

    const [isClientsAccount, setIsClientsAccount] = useState(false);
    const [firstname, setFirstname] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [country, setCountry] = useState(null);

    const [loading, setLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [responseError, setResponseError] = useState(null);

    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [alreadyExists, setAlreadyExists] = useState(false);

    const cryptoOptions = [
        { key: 'btc', value: 'Bitcoin', text: 'Bitcoin', family:"Bitcoin" },
        { key: 'eth', value: 'Ethereum', text: 'Ethereum', family: "Ethereum" },
        { key: 'matic', value: 'Polygon', text: 'Polygon', family: "Ethereum" }
    ];

    const accounts = useRecoilValue(userAccountsState);
    const externals = accounts.filter((account) => account.internal === false);


    const handleChange = (e, { name, value }) => {
        switch (name) {
            case "address":
                var existing = externals.find((external) => {
                    return external.address === value && external.type === type;
                });
                if (existing)
                    setAlreadyExists(true);
                else
                    setAlreadyExists(false);
                setAddress(value);
                break;
            case "label":
                setLabel(value);
                break;
            case "type":
                existing = externals.find((external) => {
                    return external.address === (address ?? "") && external.type === value;
                });
                if (existing)
                    setAlreadyExists(true);
                else
                    setAlreadyExists(false);
                setType(value);
                break;
            case "isClientsAccount":
                setIsClientsAccount(value);
                break;
            case "firstname":
                setFirstname(value);
                break;
            case "lastname":
                setLastname(value);
                break;
            case "country":
                setCountry(value);
                break;
            case "otp":
                setOtp(value);
                break;
            case "password":
                setPassword(value);
                break;
            default:
                console.warn(`Unhandled form input name: ${name}`);
                break;
        }
    };

    const handleModal = (isOpen) => {
        setIsModalOpen(isOpen);
    };

    const goToStep = async (step) => {
        if (step === 1) {
        }
        else if (step === 2) {
            if (!label || !address) return;
        }
        else if (step === 3) {
            if (!isClientsAccount && (!firstname || !lastname || !country)) {
                return;
            }
        }
        else if (step === 4) {
            setLoading(true);
            setIsModalOpen(false);
            const family = cryptoOptions.find((option) => option.value === type).family;
            const account = { "label": label, "address": address, "type": family, "internal": false, "assetId": type.toLowerCase() };
            const tier = { "firstName": firstname, "lastName": lastname, "country": country };
            const body = {
                "account": account,
                "tier": isClientsAccount === true ? null : tier,
                "credentials": {
                    "password": password,
                    "otp": otp
                }
            };
            try {
                const response = await sleepwellApi.addAccount(user.id, body);
                setApiResponse(response);
                setResponseError(null);
            } catch (error) {
                console.log("error", error);
                console.error("Error during add account:", error);
                setResponseError(error);
            } finally {
                setLoading(false)
            }
        }
        setCurrentStep(step);
    }
    return (
        <>
            <Header as='h3'>Ajout d'une adresse externe ({currentStep} / 4)</Header>
            <Divider />
            <Form>
                {currentStep === 1 && <AddAddressSteps.Step1 {...{ label, address, cryptoOptions, type, handleChange, goToStep, alreadyExists }} />}
                {currentStep === 2 && <AddAddressSteps.Step2 {...{ isClientsAccount, firstname, lastname, country, handleChange, goToStep }} />}
                {currentStep === 3 && <AddAddressSteps.Step3 {...{ label, address, type, isClientsAccount, user, firstname, lastname, country, goToStep, handleModal, loading }} />}
                {currentStep === 4 && <AddAddressSteps.Step4 {...{ apiResponse, isClientsAccount, user, goToStep, responseError }} />}
            </Form>

            <PasswordOtpModal
                password={password} non
                otp={otp}
                isOpen={isModalOpen}
                onClose={() => handleModal(false)}
                handleChange={handleChange}
                goToStep={goToStep}
                loading={loading}
            />
        </>
    );
}

const AddAddress = () => {
    return (<>
        <div style={{ height: '100vh' }}>
            <TopNavBarLg />
            <Grid style={{ height: 'calc(100vh - 50px)', margin: 0 }}>
                <Grid.Column width={3}>
                    <AppMenu />
                </Grid.Column>
                <Grid.Column width={13}>
                    <div style={{ padding: '20px' }}>
                        <Segment>
                            <AddForm />
                        </Segment>
                    </div>
                </Grid.Column>
            </Grid>
        </div>
    </>);
};

export default AddAddress;
