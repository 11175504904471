import 'semantic-ui-css/semantic.min.css';
import React, { useEffect, useState } from 'react';
import { Segment, Grid, Container, Dimmer, Loader } from 'semantic-ui-react';
import TopNavBarLg from '../components/common/navbar/TopNavBarLg';
import AppMenu from '../components/common/navbar/AppMenu';
import { cryptoPricesState, userIsConformiteState } from '../states/AppState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useKeycloak } from '@react-keycloak/web';
import { sleepwellApi } from '../utils/SleepwellApi';
import TransactionDetailsModal from '../components/usertransactions/TransactionDetailsModal';
import TransactionListTable from '../components/admins/waitingActions/TransactionListTable';
import AccountListTable from '../components/admins/waitingActions/AccountListTable';
import ClientDetailModal from '../components/admins/waitingActions/ClientDetailModal';
import AdminAccountDetailsModal from '../components/admins/waitingActions/AdminAccountDetailsModal';
import AppMenuService from '../components/common/navbar/AppMenuService';

function WaitingActions() {
    const { navigate, routes } = AppMenuService();
    const { initialized } = useKeycloak();
    const isConformite = useRecoilValue(userIsConformiteState);


    const [cryptoPrices, setCryptoPrices] = useRecoilState(cryptoPricesState);

    useEffect(() => {
        async function fetchCryptoPrices() {
            sleepwellApi.getCyrptoPrices().then((response) => {
                setCryptoPrices(response);
            }).catch((e) => {
                console.error("Error fetching crypto prices:", e);
            });
        }
        if (!cryptoPrices || Object.keys(cryptoPrices).length === 0) {
            fetchCryptoPrices();
        }
    }, [cryptoPrices, setCryptoPrices]);



    const [isTransactionModalOpen, setTransactionModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const [isAccountModalOpen, setAccountModalOpen] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);

    const [isClientModalOpen, setClientModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    const openTransactionModal = (transaction) => {
        setSelectedTransaction(transaction);
        setTransactionModalOpen(true);
    };

    const closeTransactionModal = () => {
        setSelectedTransaction(null);
        setTransactionModalOpen(false);
    };

    const openAccountModal = (account) => {
        setSelectedAccount(account);
        setAccountModalOpen(true);
    };

    const closeAccountModal = () => {
        setSelectedAccount(null);
        setAccountModalOpen(false);
    };

    const openClientModal = async (clientId) => {
        setSelectedClient(clientId);
        setClientModalOpen(true);
    };

    const closeClientModal = () => {
        setSelectedClient(null);
        setClientModalOpen(false);
    };

    useEffect(() => {
        if (!isConformite) {
            navigate(routes.home);
        }
    }, [isConformite, navigate, routes]);


    if (!initialized) {
        return (
            <Container>
                <Dimmer active>
                    <Loader content='Loading' />
                </Dimmer>
            </Container>
        )
    }


    return (
        <div style={{ height: '100vh' }}>
            <TopNavBarLg />
            <Grid style={{ height: 'calc(100vh - 50px)', margin: 0 }}>
                <Grid.Column width={3}>
                    <AppMenu />
                </Grid.Column>
                <Grid.Column width={13}>
                    <div style={{ overflowY: 'auto', padding: '20px' }}>

                        <Segment>
                            <TransactionListTable
                                openTransactionModal={openTransactionModal}
                                openClientModal={openClientModal}
                                isTransactionModalOpen={isTransactionModalOpen}
                            />

                            <AccountListTable
                                openAccountModal={openAccountModal}
                                openClientModal={openClientModal}
                                isAccountModalOpen={isAccountModalOpen}
                            />
                            <React.Fragment>
                                {isTransactionModalOpen && selectedTransaction && (
                                    <TransactionDetailsModal
                                        transaction={selectedTransaction}
                                        isOpen={isTransactionModalOpen}
                                        onClose={closeTransactionModal}
                                        price={cryptoPrices[selectedTransaction.assetId]}
                                        control={true}
                                    />
                                )}
                                {isAccountModalOpen && selectedAccount && (
                                    <AdminAccountDetailsModal
                                        account={selectedAccount}
                                        isOpen={isAccountModalOpen}
                                        onClose={closeAccountModal}
                                    />
                                )}
                                {isClientModalOpen && selectedClient && (
                                    <ClientDetailModal
                                        clientId={selectedClient}
                                        isOpen={isClientModalOpen}
                                        onClose={closeClientModal}
                                    />
                                )}
                            </React.Fragment>
                        </Segment>
                    </div>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default WaitingActions;
